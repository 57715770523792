import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'typeface-muli';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

import theme from 'hx-theme';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '../../global-styles';

import ModalRootContainer from '../ModalRootContainer';
import ToastRootContainer from '../ToastRootContainer';
import GenericErrorContainer from '../GenericErrorContainer';
import InitContainer from '../InitContainer';

import { MinResolutionScreen } from '../../components';

import { PrivateRoute, NotFound } from '../../routes';
import routes from '../../paths';

import { connect } from 'react-redux';
import { initialAuth } from '../../actions/AuthActions';
import { HeaderContainer, FooterContainer } from '..';

import { LanguageContext } from '../../contexts';
import { withAuthCheckOnPageVisibility } from '../../hoc';

const supportsHistory = 'pushState' in window.history;

class Root extends Component {
  isHomePage = () => window.location && window.location.pathname === '/';

  render() {
    const { store, auth } = this.props;

    let scriptHotjar = document.createElement('script');
    const hotjar = `;(function(h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function() {
            ;(h.hj.q = h.hj.q || []).push(arguments)
          }
        h._hjSettings = { hjid: 3780531, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')`;

    scriptHotjar.innerHTML = hotjar;
    const head = document.getElementsByTagName('head')[0];
    if (process.env.REACT_APP_API_ENV === 'production') {
      head.appendChild(scriptHotjar);
    }

    document.title = this.isHomePage()
      ? 'Home - Discover'
      : 'Account - Discover';

    return (
      <LanguageContext.Provider value={'en-US'}>
        <Provider store={store}>
          <GlobalStyles />
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <BrowserRouter basename={'/'} forceRefresh={!supportsHistory}>
                <InitContainer>
                  {auth.isLogged && !auth.isLoading && <HeaderContainer />}
                  <Switch>
                    {routes.map((route, index) =>
                      route.private ? (
                        <PrivateRoute
                          key={`route-${index}`}
                          exact={route.exact}
                          path={route.path}
                          auth={auth}
                          component={props => (
                            <route.component
                              {...props}
                              subpaths={route.routes}
                            />
                          )}
                          redirectPath={route.redirectPath}
                        />
                      ) : (
                        <Route
                          key={`route-${index}`}
                          path={route.path}
                          render={route.component}
                        />
                      )
                    )}
                    <Route
                      key={`route-404`}
                      path={'/not-found'}
                      render={NotFound}
                    />
                    <Redirect exact from="*" to="/not-found" />
                  </Switch>
                  {auth.isLogged && <ModalRootContainer />}
                  {auth.isLogged && <ToastRootContainer />}
                  {auth.isLogged && <MinResolutionScreen />}
                  <GenericErrorContainer />
                  {this.isHomePage() && <FooterContainer />}
                </InitContainer>
              </BrowserRouter>
            </React.Fragment>
          </ThemeProvider>
        </Provider>
      </LanguageContext.Provider>
    );
  }
}

const mapDispatchToProps = {
  initialAuth,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default withAuthCheckOnPageVisibility(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Root)
);
