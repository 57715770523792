import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

    html, body {
        height: 100%;
        overflow-x: hidden;
    }

    #root {
        height: 100%;
    }

    body {
        font-family: 'Roboto', sans-serif;
    }

    #root > header {
        position:fixed;
        top:0;
        left:0;
        z-index: 2000;
    }      

    a {
        text-decoration: none;
    }

    * {
        box-sizing: border-box;
        margin:0;
        padding:0;
    }

`;

export default GlobalStyles;
