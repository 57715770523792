import ConfirmModal from './ConfirmModal';
import { lazy } from 'react';

const AddUser = lazy(() => import('../modals/AddUser'));
const ProductDetail = lazy(() => import('../modals/ProductDetail'));
const ChangePassword = lazy(() => import('../modals/ChangePassword'));
const UsersBulk = lazy(() => import('../modals/UsersBulk'));
const Support = lazy(() => import('../modals/Support'));
const ToggleUserDisable = lazy(() => import('../modals/ToggleUserDisable'));
const UserDelete = lazy(() => import('../modals/UserDelete'));
const ResendInvite = lazy(() => import('../modals/ResendInvite'));
const ActivateUser = lazy(() => import('../modals/ActivateUser'));
const ChangeAvatar = lazy(() => import('../modals/ChangeAvatar'));

const modalTypes = {
  ConfirmModal,
  AddUser,
  ChangePassword,
  ProductDetail,
  UsersBulk,
  Support,
  ToggleUserDisable,
  UserDelete,
  ResendInvite,
  ActivateUser,
  ChangeAvatar,
};

export default modalTypes;
