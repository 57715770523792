import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Anchor,
  Button,
  Text,
  Loading,
  Row,
  Col,
  Feedback,
  Box,
} from 'hx-react-components';

import { validateEmail } from '../../utils';

import { requestForgotPassword } from '../../actions/AuthActions';

import { storeManager } from '../../store/ReducerManager';
import changePassword from '../../reducers/changePassword';

import { Form } from '../../components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import InputFormItem from '../../components/InputFormItem';

storeManager.registerReducers({ changePassword });

const FormButton = styled.div`
  & button,
  a {
    width: 100%;
    background-color: #232323;
    border-color: #232323;
    border-radius: 5px;

    &:hover {
      background-color: #2d2d2d;
      border-color: #2d2d2d;
    }

    &[disabled] {
      background-color: #d7d7d7;
      border-color: #d7d7d7;
    }
  }
`;

const FormContainer = styled.div`
  padding: 48px 30px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin: 1rem 0;
`;

class ForgotPasswordContainer extends Component {
  state = {
    email: '',
  };

  onForgotPasswordSubmit = e => {
    const { email } = this.state;

    this.props.requestForgotPassword(email);
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getErrors = email => ({
    email: email.length > 0 && validateEmail(email),
  });

  render() {
    const { email } = this.state;
    const { isLoading, error, message, success } = this.props.changePassword;

    const errors = this.getErrors(email);
    const canSubmit = errors.email;

    return (
      <Box minHeight="200px">
        <Loading hideMode isLoading={isLoading}>
          <FormContainer>
            <Row halign="center">
              <Col span={'auto'}>
                <Text variant="dark" size="xheadline">
                  Reset your password
                </Text>
              </Col>
            </Row>

            {!success && (
              <Box mT="small">
                <Row halign="center">
                  <Col span={'auto'}>
                    <Text
                      align="center"
                      size="xbody"
                      variant="dark"
                      weight="semi-bold"
                    >
                      Please check your email box, we have emailed your code.
                    </Text>
                  </Col>
                </Row>
              </Box>
            )}
            {error && (
              <Box mT="large" mB="large">
                <Feedback message={message} status="error" />
              </Box>
            )}
            {success && (
              <Box mT="large" mB="large">
                <Feedback
                  message="Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder."
                  status="success"
                />
              </Box>
            )}
            {success && (
              <Box mT="medium">
                <Row halign="center">
                  <Col span="auto" style={{ width: '100%' }}>
                    <FormButton>
                      <Button
                        as="a"
                        href="/login"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        Return to login
                      </Button>
                    </FormButton>
                  </Col>
                </Row>
              </Box>
            )}
            {!success && (
              <Form onSubmit={this.onForgotPasswordSubmit}>
                <Box mT="small" mB="medium">
                  <InputFormItem
                    label={'Email'}
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                </Box>
                <Row halign="center">
                  <Col span="auto" style={{ width: '100%' }}>
                    <FormButton>
                      <Button disabled={!canSubmit} type="submit">
                        Send password reset email
                      </Button>
                    </FormButton>
                  </Col>
                </Row>
              </Form>
            )}
            {!success && (
              <Row halign="center">
                <Col span={'auto'}>
                  <Box mT="small">
                    <Link to="/login">
                      <Anchor as="span" variant="dark">
                        Cancel
                      </Anchor>
                    </Link>
                  </Box>
                </Col>
              </Row>
            )}
          </FormContainer>
        </Loading>
      </Box>
    );
  }
}

const mapDispatchToProps = {
  requestForgotPassword,
};

const mapStateToProps = state => ({
  changePassword: state.changePassword,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
