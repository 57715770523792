import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Text,
  Loading,
  Row,
  Col,
  Feedback,
  Box,
  PasswordValidationFeedback,
} from 'hx-react-components';

import queryString from 'query-string';

import { PasswordFormItem, Form } from '../../components';

import {
  requestRegistration,
  requestCheckActivationToken,
  resetAuth,
} from '../../actions/AuthActions';

import { storeManager } from '../../store/ReducerManager';
import checkValidationToken from '../../reducers/checkValidationToken';

import { newPasswordRules, isFormValid } from '../../utils';

import { withClearSession } from '../../hoc';
import styled from 'styled-components';
import InputFormItem from '../../components/InputFormItem';

storeManager.registerReducers({ checkValidationToken });

const FormContainer = styled.div`
  padding: 48px 30px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin: 1rem 0;
`;

const FormButton = styled.div`
  & button,
  a {
    width: 100%;
    background-color: #232323;
    border-color: #232323;
    border-radius: 5px;

    &:hover {
      background-color: #2d2d2d;
      border-color: #2d2d2d;
    }

    &[disabled] {
      background-color: #d7d7d7;
      border-color: #d7d7d7;
    }
  }
`;

class RegistrationContainer extends Component {
  state = {
    name: '',
    surname: '',
    email: '',
    newPasswordIsValid: false,
    touched: {},
  };

  setTouched = item => {
    const { touched } = this.state;
    const newVal = touched;
    newVal[item] = true;
    this.setState({
      ...this.state,
      touched: newVal,
    });
  };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (!values.verification_code || !values.email) {
      this.props.history.push('/login');

      return false;
    }

    this.props.requestCheckActivationToken(
      values.email,
      values.verification_code
    );

    this.setState({
      email: values.email,
    });
  }

  componentDidUpdate(prevProps) {
    const { checkValidationToken, auth } = this.props;
    const { valid, isLoading } = checkValidationToken;
    const { isLogged } = auth;

    const tokenValidationFailed =
      prevProps.checkValidationToken.isLoading && !isLoading && !valid;

    if (tokenValidationFailed) {
      this.props.history.push('/invitation-expired');
    }

    if (isLogged) {
      this.props.history.push('/');
    }
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOnRegistrationSubmit = () => {
    const { name, surname, email, password } = this.state;
    const values = queryString.parse(this.props.location.search);
    this.props.requestRegistration(
      email,
      name,
      surname,
      values.verification_code,
      password
    );
  };

  setNewPasswordValid = isValid => {
    const { newPasswordIsValid } = this.state;
    if (newPasswordIsValid !== isValid) {
      this.setState({
        newPasswordIsValid: isValid,
      });
    }
  };

  getValidation = (
    name,
    surname,
    newPasswordIsValid,
    password,
    confirm_password
  ) => ({
    name: name.length > 0,
    surname: surname.length > 0,
    newPasswordIsValid,
    passwordsMatch: password === confirm_password,
  });

  render() {
    const {
      name,
      surname,
      email,
      password,
      confirm_password,
      newPasswordIsValid,
      touched,
    } = this.state;

    const { isLoading, error, message } = this.props.auth;
    const checkValidationTokenLoading = this.props.checkValidationToken
      .isLoading;

    const loading = isLoading || checkValidationTokenLoading;

    const validation = this.getValidation(
      name,
      surname,
      newPasswordIsValid,
      password,
      confirm_password
    );

    const canSubmit = isFormValid(validation);

    return (
      <Box minHeight="200px">
        <Loading hideMode isLoading={loading}>
          <FormContainer>
            <Row halign="center">
              <Col span={'auto'}>
                <Text variant="dark" size="xheadline">
                  Create your account!
                </Text>
              </Col>
            </Row>
            {error && (
              <Box mT="large" mB="large">
                <Feedback message={message} status="error" />
              </Box>
            )}
            <Form onSubmit={this.handleOnRegistrationSubmit}>
              <Box mT="medium" mB="medium">
                <InputFormItem
                  label={'Email'}
                  type="email"
                  name="email"
                  value={email}
                  readonly
                  disabled
                />
              </Box>
              <Box mB="medium">
                <InputFormItem
                  label={'First Name'}
                  error={touched.name && !validation.name}
                  message="Name is required"
                  type="text"
                  name="name"
                  autoFocus
                  tabIndex={1}
                  value={name}
                  onBlur={e => this.setTouched(e.target.name)}
                  onChange={this.handleInputChange}
                />
              </Box>
              <Box mB="medium">
                <InputFormItem
                  label={'Last Name'}
                  error={touched.surname && !validation.surname}
                  message="Last Name is required"
                  type="text"
                  name="surname"
                  tabIndex={2}
                  value={surname}
                  onBlur={e => this.setTouched(e.target.name)}
                  onChange={this.handleInputChange}
                />
              </Box>
              <Box mB="medium">
                <PasswordFormItem
                  tabIndex={3}
                  formItemVariant="dark_lighter"
                  onPasswordChange={this.handleInputChange}
                  placeholder=""
                  name="password"
                  autocomplete="new-password"
                  value={password}
                  label={'Define your password'}
                />
              </Box>
              <Box mB="medium">
                <Row>
                  <Col>
                    {!newPasswordIsValid && (
                      <Box mB="small">
                        <Text size="small" variant="dark" weight="semi-bold">
                          Be sure your password contains at list:
                        </Text>
                      </Box>
                    )}
                    <PasswordValidationFeedback
                      variant="dark"
                      rules={newPasswordRules}
                      password={password}
                      onValidationChange={this.setNewPasswordValid}
                      feedbackText="Password is secure!"
                    />
                  </Col>
                </Row>
              </Box>
              <Box mB="medium">
                <PasswordFormItem
                  tabIndex={4}
                  formItemVariant="dark_lighter"
                  onPasswordChange={this.handleInputChange}
                  placeholder=""
                  name="confirm_password"
                  autocomplete="new-password"
                  value={confirm_password}
                  onBlur={e => this.setTouched(e.target.name)}
                  error={touched.confirm_password && !validation.passwordsMatch}
                  errorMessage={'Passwords are not matching'}
                  label={'Confirm your password'}
                />
              </Box>
              <Row halign="center">
                <Col span="auto" style={{ width: '100%' }}>
                  <FormButton>
                    <Button disabled={!canSubmit} type="submit">
                      Complete
                    </Button>
                  </FormButton>
                </Col>
              </Row>
            </Form>
          </FormContainer>
        </Loading>
      </Box>
    );
  }
}

const mapDispatchToProps = {
  requestRegistration,
  requestCheckActivationToken,
  resetAuth,
};

const mapStateToProps = state => ({
  auth: state.auth,
  checkValidationToken: state.checkValidationToken,
});

export default withRouter(
  withClearSession(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RegistrationContainer)
  )
);
