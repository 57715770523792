import React from 'react';
import styled, { css } from 'styled-components';
import uniqid from 'uniqid';
import { Col, Row, Text } from 'hx-react-components';

const getBorderColor = props => {
  const { error, theme, disabled } = props;

  const borderColor =
    error && !disabled ? theme.colors.error : disabled ? '#d7d7d7' : '#E0E0E0';

  return `border-color: ${borderColor}`;
};

const getHoverStyle = props => {
  const { disabled } = props;

  if (disabled) {
    return '';
  }

  return css`
    &:hover {
      border: 1px solid #f15e24;
    }
  `;
};

const StyledLabel = styled.label`
  display: block;
  position: relative;
  max-height: 0;
  font-weight: 500;
  pointer-events: none;

  &::before {
    color: #232323;
    content: attr(data-content);
    display: inline-block;
    filter: blur(0);
    backface-visibility: hidden;
    transform-origin: left top;
    transition: transform 0.2s ease;
    left: 1rem;
    position: relative;
  }

  &::after {
    bottom: 1rem;
    content: '';
    height: 0.1rem;
    position: absolute;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
    opacity: 0;
    left: 0;
    top: 100%;
    margin-top: -0.1rem;
    transform: scale3d(0, 1, 1);
    width: 100%;
  }
`;

const StyledInput = styled.input`
  padding: 1.8rem 1rem 0.6rem;
  font-size: 1rem;
  outline: none;
  border: none;
  background: transparent;

  &[disabled] {
    color: ${props => props.theme.colors.dark_lighter};
    pointer-events: none;

    ::-webkit-input-placeholder {
      color: ${props => props.theme.colors.dark_lighter};
    }
  }
`;

const StyledInputContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 60px;
  border-radius: 4px;
  border-style: solid;
  border-width: ${props => (props.error && !props.disabled ? '2px' : '1px')};
  ${props => getBorderColor(props)};
  background: ${props => (props.disabled ? '#D7D7D7' : '#ffffff')};
  justify-content: center;

  ${props => getHoverStyle(props)}
  &:focus-within {
    border: 2px solid #f15e24;
  }

  ${StyledInput}:focus + ${StyledLabel}::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  ${StyledInput}:placeholder-shown + ${StyledLabel}::before {
    transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
  }

  ${StyledLabel}::before, ${StyledInput}:focus + ${StyledLabel}::before {
    transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
  }
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  right: ${props => props.theme.spacing.medium};
`;

const InputFormItem = React.forwardRef((props, ref) => {
  const id = `input-${uniqid()}`;
  const {
    label,
    type = 'text',
    value = '',
    disabled = false,
    error = false,
    onClick,
    onFocus,
    onChange,
    onBlur,
    readonly = false,
    withIcon,
    autoComplete = 'on',
    message,
    name,
    ...rest
  } = props;
  return (
    <>
      <StyledInputContainer error={error} disabled={disabled}>
        <StyledInput
          id={id}
          ref={ref}
          name={name}
          disabled={disabled}
          error={error}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder=""
          onChange={onChange}
          value={value}
          type={type}
          withIcon={withIcon}
          readonly={readonly}
          autoComplete={autoComplete}
          {...rest}
        />
        <StyledLabel htmlFor={id} data-content={label} />
        {withIcon && <StyledButtonContainer>{withIcon}</StyledButtonContainer>}
      </StyledInputContainer>
      {error && (
        <Row>
          <Col>
            <Text tag="span" variant="error" size="small">
              {message}
            </Text>
          </Col>
        </Row>
      )}
    </>
  );
});
export default InputFormItem;
