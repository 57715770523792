import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Text,
  Loading,
  Row,
  Col,
  Feedback,
  Box,
  PasswordValidationFeedback,
} from 'hx-react-components';

import { storeManager } from '../../store/ReducerManager';
import changePassword from '../../reducers/changePassword';
import { PasswordFormItem, Form } from '../../components';
import { newPasswordRules } from '../../utils';

import { withClearSession } from '../../hoc';

import {
  requestResetPassword,
  requestResetPwdReActivate,
} from '../../actions/AuthActions';

import queryString from 'query-string';
import styled from 'styled-components';

storeManager.registerReducers({ changePassword });

const FormButton = styled.div`
  & button,
  a {
    width: 100%;
    background-color: #232323;
    border-color: #232323;
    border-radius: 5px;

    &:hover {
      background-color: #2d2d2d;
      border-color: #2d2d2d;
    }

    &[disabled] {
      background-color: #d7d7d7;
      border-color: #d7d7d7;
    }
  }
`;

const FormContainer = styled.div`
  padding: 48px 30px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin: 1rem 0;
`;

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.passwordRef = React.createRef();
    this.confirmPasswordRef = React.createRef();
    this.state = {
      password: '',
      confirmPassword: '',
      isValid: false,
    };
  }

  componentDidUpdate() {
    const { auth } = this.props;
    const { isLogged } = auth;

    if (isLogged) {
      this.props.history.push('/');
    }
    this.timer = setInterval(
      () =>
        this.setState(prevState => ({
          ...prevState,
          password:
            (this.passwordRef &&
              this.passwordRef.current &&
              this.passwordRef.current.value) ||
            '',
          confirmPassword:
            (this.confirmPasswordRef &&
              this.confirmPasswordRef.current &&
              this.confirmPasswordRef.current.value) ||
            '',
        })),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleInputChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  setNewPasswordValid = valid => {
    if (valid === this.state.isValid) {
      return false;
    }

    this.setState({
      isValid: valid,
    });
  };

  onResetPasswordSubmit = () => {
    const { password } = this.state;

    const value = queryString.parse(this.props.location.search);
    const { email, verification_code, reactivate } = value;

    if (!reactivate) {
      this.props.requestResetPassword(email, verification_code, password);
      return;
    }

    this.props.requestResetPwdReActivate(email, verification_code, password);
  };

  render() {
    const { password, confirmPassword, isValid } = this.state;
    const value = queryString.parse(this.props.location.search);
    const { reactivate } = value;

    const canSubmit = isValid && password === confirmPassword;

    const { isLoading, error, message, success } = this.props.changePassword;

    return (
      <Box minHeight="200px">
        <Loading hideMode isLoading={isLoading}>
          <FormContainer>
            <Row halign="center">
              <Col span={'auto'}>
                <Text size="xheadline" align="center">
                  {!reactivate ? 'Change password' : 'Choose a password'}
                </Text>
                {reactivate && (
                  <Box mT="medium">
                    <Text
                      align="center"
                      size="xbody"
                      variant="dark"
                      weight="semi-bold"
                    >
                      Choose a password to re-activate your user
                    </Text>
                  </Box>
                )}
              </Col>
            </Row>
            {error && (
              <Box mb="large" mT="large">
                <Feedback message={message} status="error" />
              </Box>
            )}
            {success && (
              <Box mT="large" mB="large">
                <Feedback
                  message="Your password was succesfully updated."
                  status="success"
                />
              </Box>
            )}
            {success && (
              <Box mT="large" mB="large">
                <Row halign="center">
                  <Col span="auto" style={{ width: '100%' }}>
                    <FormButton>
                      <Button
                        as="a"
                        href="/login"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        Return to login
                      </Button>
                    </FormButton>
                  </Col>
                </Row>
              </Box>
            )}
            {!success && (
              <Form onSubmit={this.onResetPasswordSubmit}>
                <Box mT="large">
                  <Box mB="medium">
                    <PasswordFormItem
                      refs={this.passwordRef}
                      formItemVariant="dark_lighter"
                      tabIndex={1}
                      onPasswordChange={this.handleInputChange}
                      placeholder=""
                      name="password"
                      autoComplete="new-password"
                      autoFocus
                      value={password}
                      label={'Define your password'}
                      isNewPassword={true}
                    />
                  </Box>
                  <Box mB="medium">
                    <Row>
                      <Col>
                        {!isValid && (
                          <Box mB="small">
                            <Text
                              size="small"
                              variant="dark"
                              weight="semi-bold"
                            >
                              Be sure your password contains at list:
                            </Text>
                          </Box>
                        )}
                        <PasswordValidationFeedback
                          variant="dark"
                          rules={newPasswordRules}
                          password={password}
                          onValidationChange={this.setNewPasswordValid}
                          feedbackText="Password is secure!"
                        />
                      </Col>
                    </Row>
                  </Box>
                  <Box mT="small" mB="medium">
                    <PasswordFormItem
                      refs={this.confirmPasswordRef}
                      formItemVariant="dark_lighter"
                      tabIndex={2}
                      onPasswordChange={this.handleInputChange}
                      placeholder=""
                      name="confirmPassword"
                      autoComplete="off"
                      value={confirmPassword}
                      error={password !== confirmPassword}
                      errorMessage={'Passwords are not matching'}
                      label={'Confirm your password'}
                    />
                  </Box>
                  <Row halign="center">
                    <Col span="auto" style={{ width: '100%' }}>
                      <FormButton>
                        <Button
                          disabled={!canSubmit}
                          type="submit"
                          tabIndex={3}
                        >
                          {!reactivate ? 'Change password' : 'Activate'}
                        </Button>
                      </FormButton>
                    </Col>
                  </Row>
                </Box>
              </Form>
            )}
          </FormContainer>
        </Loading>
      </Box>
    );
  }
}

const mapDispatchToProps = {
  requestResetPassword,
  requestResetPwdReActivate,
};

const mapStateToProps = state => ({
  auth: state.auth,
  changePassword: state.changePassword,
});

export default withRouter(
  withClearSession(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ResetPasswordContainer)
  )
);
