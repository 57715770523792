import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableCol,
  TableHead,
  Checkbox,
  UserInfo,
  ListItem,
  ListGroup,
  ButtonIcon,
  Button,
  Text,
  Anchor,
  Tooltip,
  Loading,
  Box,
  EmptyState,
  InfoLabel,
} from 'hx-react-components';

import { PaginationBox } from '../';

import {
  getFormattedDate,
  getInitials,
  getFullName,
  isEmpty,
  objectIsNull,
} from '../../utils';
import { sendEvent } from '../../actions/EventsActions';

class UsersList extends Component {
  state = {
    checkedRows: 0,
    tableHead: {
      cols: [
        {
          name: 'name',
          label: 'User',
          order: {
            active: false,
            value: 'asc',
          },
        },
        {
          name: 'status',
          label: 'Status',
          order: {
            active: false,
            value: 'desc',
          },
        },
        {
          name: 'type',
          label: 'Admin. functions',
        },
        {
          name: 'product',
          label: 'Products',
        },
        {
          name: 'last_login',
          label: 'Last Login',
          order: {
            active: false,
            value: 'desc',
          },
        },
      ],
    },
    isConfirmModalOpen: false,
  };

  handleOnOrderChange = colIndex => {
    const { tableHead } = this.state;
    const cols = tableHead.cols.map((col, index) => {
      if (!col.order) {
        return col;
      }

      if (colIndex === index) {
        if (col.order.active) {
          col.order.value = col.order.value === 'desc' ? 'asc' : 'desc';
        }
        col.order.active = true;

        return col;
      }

      col.order.active = false;

      return col;
    });

    const sort = {
      orderBy: cols[colIndex].name,
      order: cols[colIndex].order.value,
    };

    this.props.getUsers({ sort: sort });

    this.setState({
      ...this.state,
      tableHead: {
        ...this.state.tableHead,
        cols,
      },
    });
  };

  getHeadCols() {
    const { tableHead } = this.state;
    const cols = tableHead.cols.map((col, index) => (
      <TableCol
        span={col.name === 'name' ? 2 : null}
        onClick={() => {
          this.handleOnOrderChange(index);
        }}
        key={`col-head-${index}`}
        order={col.order}
      >
        <Text size="xbody">{col.label}</Text>
      </TableCol>
    ));

    return cols;
  }

  getSelectedUsers = () => {
    const { users } = this.props;
    return users.data.filter(item => item.selected);
  };

  getUserDisabled = userId => {
    const { users } = this.props;
    return users.data.filter(item => item.id === userId);
  };

  getSelectedIds = selectedUsers => {
    return selectedUsers.map(selected => selected.id);
  };

  bulkResetPassword = () => {
    const selectedUsers = this.getSelectedUsers();

    this.props.showModal(
      {
        isOpen: true,
        position: 'center',
        selectedUsers,
        bulkType: 'resetPasswords',
      },
      'UsersBulk'
    );
  };

  bulkDisableUsers = () => {
    const selectedUsers = this.getSelectedUsers();

    this.props.showModal(
      {
        isOpen: true,
        position: 'center',
        selectedUsers,
        bulkType: 'disableUsers',
      },
      'UsersBulk'
    );
  };

  toggleDisableUser = (userId, statusType) => {
    const selectedUser = this.getUserDisabled(userId);

    this.props.showModal(
      {
        isOpen: true,
        position: 'center',
        selectedUser,
        statusType,
      },
      'ToggleUserDisable'
    );
  };

  getBulkActionsList() {
    return (
      <ListGroup
        size="small"
        direction="row"
        items={[
          <Tooltip content="Disable" isInfoLabel>
            <ButtonIcon
              icon="hide"
              size="small"
              data-test-selector="hx-test-bulk-disable"
              onClick={this.bulkDisableUsers}
            />
          </Tooltip>,
          <Tooltip content="Reset Password" isInfoLabel>
            <ButtonIcon
              icon="lock"
              size="small"
              onClick={this.bulkResetPassword}
            />
          </Tooltip>,
        ]}
      />
    );
  }

  getSelectedCount() {
    const { users } = this.props;
    return users.data.filter(item => {
      return item.selected;
    }).length;
  }

  tableCheckedStatus() {
    const { users } = this.props;
    const rowsCount = this.getSelectedCount();

    if (rowsCount === 0) {
      return false;
    }

    if (rowsCount === users.data.length) {
      return true;
    }

    return 'semi-checked';
  }

  handleAllRowSelect = () => {
    const { users } = this.props;
    const rows = users.data;
    const allSelected = this.tableCheckedStatus();

    rows.forEach(item => {
      if (this.isUserEditableWithBulk(item)) {
        item.selected = !allSelected;
      }
    });
    this.setState({
      ...this.state,
      rows,
    });
  };

  // At the moment we only have one user scope, in the fututre in user will have more than one scope we'll show icons instead of text
  getUserAdmFunctions = user => {
    if (isEmpty(user.scopes)) {
      return (
        <Text size="small" lettercase="capitalize">
          -
        </Text>
      );
    }

    const scopes = user.scopes.map(scope => (
      <Tooltip content={`Can view and edit ${scope.name}`}>
        <Text size="small">{scope.name}</Text>
      </Tooltip>
    ));

    return <ListGroup direction="row" items={scopes} />;
  };

  isUserOwner = user => {
    return user.type === 'owner';
  };

  isUserEditableWithBulk = user => {
    return !this.isUserOwner(user) && user.status === 'active';
  };

  getUserProducts = products => {
    if (isEmpty(products)) {
      return '-';
    }
    if (products.length > 1) {
      const tooltipContent = products.map((item, index) => (
        <Text key={index} variant="light" size="small">
          {item.name}
        </Text>
      ));
      return (
        <span style={{ pointerEvents: 'initial' }}>
          <Tooltip content={tooltipContent}>
            <Anchor>{products.length} products</Anchor>
          </Tooltip>
        </span>
      );
    }

    return <Text size="small">{products[0].name}</Text>;
  };

  getFormattedDate = date => {
    const formattedDate = getFormattedDate(date, 'MM/DD/YYYY');
    const time = getFormattedDate(date, 'h:mm a');

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text tag="p" size="small">
          {formattedDate}
        </Text>
        <Text size="small">{time}</Text>
      </div>
    );
  };

  handleOnUserSendReminder = user => {
    this.props.showModal(
      {
        isOpen: true,
        position: 'center',
        selectedUser: user,
      },
      'ResendInvite'
    );
  };

  getUserInfo = user => {
    const { id, avatar, email, status } = user;
    const buttonText = status === 'pending' && 'Send a Reminder';

    const initials = getInitials(user);
    let fullName = getFullName(user);

    if (this.isUserOwner(user)) {
      fullName = fullName + ' (Owner)';
    }

    if (status === 'pending') {
      return (
        <UserInfo
          size="medium"
          buttonOnClick={e => {
            e.preventDefault();
            this.handleOnUserSendReminder(user);
          }}
          buttonText={buttonText}
          initials={initials}
          email={email}
        />
      );
    }

    return (
      <UserInfo
        size="medium"
        buttonOnClick={() => this.handleOnUserSendReminder(id)}
        userName={fullName}
        initials={initials}
        image={avatar}
        email={email}
      />
    );
  };

  getUserStatus = status => {
    const map = {
      active: 'success',
      pending: 'pending',
    };

    const variant = map[status] ? map[status] : 'default';

    return <InfoLabel label={status} variant={variant} />;
  };

  getPagination() {
    const { users } = this.props;
    const { params } = users;

    if (!users.pageCount) {
      return false;
    }

    const pagination = {
      ...params.pagination,
      pageCount: users.pageCount,
    };

    return (
      <PaginationBox
        pagination={pagination}
        onPageChange={this.props.onPageChange}
      />
    );
  }

  deleteUserRequest = user => {
    const selectedUsers = [user];

    this.props.showModal(
      {
        isOpen: true,
        position: 'center',
        selectedUsers,
      },
      'UserDelete'
    );
  };

  resetUserPasswordRequest = user => {
    const selectedUsers = [user];

    this.props.showModal(
      {
        isOpen: true,
        position: 'center',
        selectedUsers,
        bulkType: 'resetPasswords',
      },
      'UsersBulk'
    );
  };

  getUserActions = user => {
    if (this.isUserOwner(user) || user.selected || user.status === 'pending') {
      return null;
    }

    if (user.status === 'inactive') {
      return [
        <ListItem
          iconLeft={'edit'}
          text={'Edit'}
          onClick={() => {
            this.props.history.push(`/users/${user.id}`);
          }}
        />,
        <ListItem
          iconLeft={'delete'}
          text={'Delete'}
          onClick={() => this.deleteUserRequest(user)}
        />,
      ];
    }

    const actions = [
      <ListItem
        iconLeft={'edit'}
        text={'Edit'}
        onClick={() => {
          sendEvent('Account Settings - Edit user');
          this.props.history.push(`/users/${user.id}`);
        }}
      />,
      <ListItem
        iconLeft={'hide'}
        text={'Disable'}
        data-test-selector="hx-test-toggle-disabled"
        onClick={() => {
          sendEvent('Account Settings - Disable user');
          this.toggleDisableUser(user.id, 'disableUser');
        }}
      />,
      <ListItem
        iconLeft={'lock'}
        text={'Reset password'}
        onClick={() => {
          sendEvent('Account Settings - Reset user password');
          this.resetUserPasswordRequest(user);
        }}
      />,
    ];
    return actions;
  };

  showAddUsersEmptyState = users => {
    const hasOnlyOneUser = users.data.length === 1;
    if (!hasOnlyOneUser) {
      return false;
    }

    const { params } = users;

    const noFiltersApplied =
      isEmpty(params.filters) && params.q === '' && objectIsNull(params.sort);

    const onlyUserIsOwner = users.data[0].type === 'owner';
    if (noFiltersApplied && onlyUserIsOwner) {
      return true;
    }
  };

  openAddUserModal = () => {
    this.props.showModal(
      {
        isOpen: true,
        position: 'right',
      },
      'AddUser'
    );
  };

  render() {
    const { users } = this.props;
    const cols = this.getHeadCols();
    const pagination = this.getPagination();
    const bulkActions = this.getBulkActionsList();
    const allSelected = this.tableCheckedStatus();
    const selectedCounter = this.getSelectedCount();
    const isEmptyState = isEmpty(users.data);
    const showAddUsersEmptyState = this.showAddUsersEmptyState(users);
    return (
      <React.Fragment>
        <Box mB="xxlarge" minHeight="500px">
          <Loading isLoading={users.isLoading}>
            <Table>
              <TableHead
                hasSelectAll
                selectedCounter={selectedCounter}
                onAllCheckboxToogle={() =>
                  this.props.onToggleAllUserSelection(!allSelected)
                }
                allSelected={allSelected}
                cols={cols}
                bulkActions={bulkActions}
              />
              {isEmptyState && (
                <EmptyState
                  icon="users"
                  mainText="None of your users matched this search"
                />
              )}
              {!isEmptyState &&
                users.data.map(user => (
                  <TableRow
                    key={`row-${user.id}`}
                    isSelected={user.selected}
                    isDisabled={user.deleting}
                    isActionOpen={user.actionOpen}
                    forceActionsPadding={
                      this.isUserOwner(user) || user.selected
                    }
                    actions={this.getUserActions(user)}
                    onRowClick={() => this.props.onToggleUserSelection(user.id)}
                    isNotSelectable={
                      user.deleting || !this.isUserEditableWithBulk(user)
                    }
                  >
                    <TableCol auto>
                      <Checkbox
                        checked={user.selected}
                        disabled={
                          user.deleting || !this.isUserEditableWithBulk(user)
                        }
                        onChange={() =>
                          this.props.onToggleUserSelection(user.id)
                        }
                      />
                    </TableCol>
                    <TableCol span={2}>{this.getUserInfo(user)}</TableCol>
                    <TableCol>{this.getUserStatus(user.status)}</TableCol>
                    <TableCol>{this.getUserAdmFunctions(user)}</TableCol>
                    <TableCol>{this.getUserProducts(user.products)}</TableCol>
                    <TableCol>
                      {this.getFormattedDate(user.last_login)}
                    </TableCol>
                  </TableRow>
                ))}
            </Table>
            {showAddUsersEmptyState && (
              <EmptyState
                icon="users"
                mainText="It's great to share"
                smallText="Add some users"
                button={
                  <Button
                    size="small"
                    variant="primary"
                    data-test-selector="hx-addUser-button"
                    onClick={this.openAddUserModal}
                  >
                    Add User
                  </Button>
                }
              />
            )}
          </Loading>
        </Box>
        <Box>{pagination}</Box>
      </React.Fragment>
    );
  }
}

export default withRouter(UsersList);
