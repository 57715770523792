import { CALL_API } from '../middleware/api';

import {
  GET_SCOPES_REQUEST,
  GET_SCOPES_SUCCESS,
  GET_SCOPES_FAILURE,
} from '../constants/action-types';

// Get Projects
const fetchScopes = () => ({
  [CALL_API]: {
    types: [GET_SCOPES_REQUEST, GET_SCOPES_SUCCESS, GET_SCOPES_FAILURE],
    endpoint: '/catalog/scopes',
    method: 'GET',
    mock: false,
  },
});

export const requestScopes = () => dispatch => {
  return dispatch(fetchScopes());
};

export const requestScopesEditUser = () => dispatch => {};
