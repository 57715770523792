import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Checkbox, Text, Box } from 'hx-react-components';
import styled from 'styled-components';
import ProductFusionData from '../ProductFusionData';

const StyledProduct = styled.div`
  border-radius: 6px;
  box-shadow: rgb(25 25 39 / 25%) 0px 2px 6px 0px;
`;

const isProductInternal = slug =>
  slug === 'hx-engine-ui' || slug === 'helixa-csm';

const isProductAssignmentDisabled = (product, canAssignInternalProducts) => {
  if (isProductInternal(product.slug) && !canAssignInternalProducts) {
    return true;
  }
  return product.licenses.available <= 0 && !product.assigned;
};

const ProductsCheckboxList = ({
  products,
  canAssignInternalProducts,
  onProductCheck,
}) => {
  const onFusionChange = (checked, productId, fusionId) => {
    onProductCheck(checked, productId, undefined, fusionId);
  };

  return products.map((product, productIndex) => (
    <StyledProduct key={productIndex}>
      <Box
        mB="medium"
        pX="medium"
        pY="medium"
        borderSize="1px"
        borderPosition="bottom"
      >
        <Row valign="middle">
          <Col span="auto">
            <Checkbox
              checked={product.assigned}
              tabIndex={productIndex + 2}
              disabled={isProductAssignmentDisabled(
                product,
                canAssignInternalProducts
              )}
              onChange={e => {
                onProductCheck(e.target.checked, product.id);

                if (e.target.checked) {
                  if (product.slug === 'affinity-us-gwi') {
                    const fusion = product.fusions.find(
                      fusion => fusion.slug === 'gwi'
                    );
                    onFusionChange(e.target.checked, product.id, fusion.id);
                  }
                }
              }}
            >
              <Text>{product.name}</Text>
            </Checkbox>
          </Col>
          <Col>
            <Text
              variant="dark_lighter"
              size="xbody"
              style={{ textAlign: 'right' }}
            >
              {product.licenses.available} licenses left
            </Text>
          </Col>
        </Row>
      </Box>
      {product.assigned && product.fusions && product.fusions.length > 0 && (
        <Box pX="medium">
          <Row>
            <Col span={12}>
              <ProductFusionData
                productId={product.id}
                productSlug={product.slug}
                fusions={product.fusions}
                onFusionChange={onFusionChange}
              />
            </Col>
          </Row>
        </Box>
      )}

      {product.addons && product.addons.length > 0 && (
        <Box pT="medium" pX="medium">
          {product.addons.map((addon, addonIndex) => {
            return (
              <Box key={`addon-${addonIndex}`} pL="large" mB="small">
                <Row valign="middle">
                  <Col span="auto">
                    <Checkbox
                      disabled={!products[productIndex].assigned}
                      checked={
                        products[productIndex].addons[addonIndex].assigned
                      }
                      onChange={e =>
                        onProductCheck(e.target.checked, product.id, addon.id)
                      }
                    >
                      <Text>{addon.name}</Text>
                    </Checkbox>
                  </Col>
                </Row>
              </Box>
            );
          })}
        </Box>
      )}
    </StyledProduct>
  ));
};

ProductsCheckboxList.propTypes = {
  products: PropTypes.array.isRequired,
  canAssignInternalProducts: PropTypes.bool,
  onProductCheck: PropTypes.func.isRequired,
};

ProductsCheckboxList.defaultProps = {
  products: [],
  canAssignInternalProducts: false,
};

export default ProductsCheckboxList;
