import React from 'react';
import { NotLoggedLayout, AuthLayout } from '../layouts';

import { Text, Row, Col, Box } from 'hx-react-components';

const GenericError = () => (
  <NotLoggedLayout>
    <AuthLayout>
      <Box mB="large" mT="xlarge">
        <Row halign="center">
          <Col span={'auto'}>
            <Text
              align="center"
              tag="h1"
              variant="dark_lighter"
              size="headline"
            >
              WE ARE SORRY
              <br />
              SOMETHING WENT WRONG ON OUR END.
              <br />
              WE ARE CURRENTLY TRYING TO FIX THE PROBLEM.
              <br />
              <br />
            </Text>
            <Text
              align="center"
              tag="h2"
              variant="dark_lighter"
              size="xheadline"
            >
              PLEASE, WAIT A FEW MINUTES.
            </Text>
          </Col>
        </Row>
      </Box>
    </AuthLayout>
  </NotLoggedLayout>
);

export default GenericError;
