import React from 'react';
import styled from 'styled-components';
import { SupportModalOpener } from '../containers';
const StyledLoggedMain = styled.section`
  padding-top: 56px;
  background-color: #ffffff;
  min-height: calc(100% - 56px);
`;

const LoggedLayout = ({ children }) => {
  return (
    <React.Fragment>
      <StyledLoggedMain>{children}</StyledLoggedMain>
      <SupportModalOpener />
    </React.Fragment>
  );
};

export default LoggedLayout;
