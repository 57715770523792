import {
  RESET_PASSWORDS_REQUEST,
  RESET_PASSWORDS_SUCCESS,
  RESET_PASSWORDS_FAILURE,
} from '../../constants/action-types';

const getInitialState = () => {
  return {
    isLoading: false,
    error: false,
  };
};

const initialState = getInitialState();

const resetPasswords = (state = initialState, action) => {
  const types = {
    [RESET_PASSWORDS_REQUEST]: () => ({
      ...state,
      isLoading: true,
    }),
    [RESET_PASSWORDS_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      error: false,
    }),
    [RESET_PASSWORDS_FAILURE]: () => ({
      ...state,
      isLoading: false,
      error: true,
      message: action.message,
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default resetPasswords;
