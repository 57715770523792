import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Button, Row, Col, Text, Box } from 'hx-react-components';

import ProductIcon from '../ProductIcon';

const ProductPreviewBox = ({ product }) => (
  <Panel
    footer={
      <Button
        as="a"
        target="_blank"
        href="mailto:info@helixa.ai"
        size="small"
        type="submit"
        variant="outlined"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Contact us
      </Button>
    }
  >
    <Row halign="between">
      <Col span={9}>
        <Box mB="xsmall">
          <Text tag="h3" size="xheadline" weight="semi-bold">
            {product.name}
          </Text>
        </Box>
        <Text tag="span" size="xbody" variant="dark_light">
          {product.description}
        </Text>
      </Col>
      <Col span="auto">
        <ProductIcon product={product} />
      </Col>
    </Row>
  </Panel>
);

ProductPreviewBox.defaultProps = {
  products: {},
};

ProductPreviewBox.propTypes = {
  products: PropTypes.object.isRequired,
};

export default ProductPreviewBox;
