import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  RESET_USERS_PARAMS,
  RESET_USERS_FILTERS,
  DISABLE_USERS_REQUEST,
  DISABLE_USERS_SUCCESS,
  DISABLE_USERS_FAILURE,
  REACTIVATE_DISABLE_USER_REQUEST,
  REACTIVATE_DISABLE_USER_SUCCESS,
  REACTIVATE_DISABLE_USER_FAILURE,
  RESET_PASSWORDS_REQUEST,
  RESET_PASSWORDS_SUCCESS,
  RESET_PASSWORDS_FAILURE,
  RESEND_USER_INVITE_REQUEST,
  RESEND_USER_INVITE_SUCCESS,
  RESEND_USER_INVITE_FAILURE,
  TOGGLE_SELECT_USER,
  TOGGLE_SELECT_ALL_USERS,
} from '../../constants/action-types';

const canUserBeSelected = user => {
  return user.type !== 'owner' && user.status === 'active';
};

const toggleSelectUser = (users, id) => {
  return users.map(item => {
    if (item.id !== id) {
      return item;
    }

    if (!canUserBeSelected(item)) {
      return item;
    }

    return {
      ...item,
      selected: !item.selected,
    };
  });
};

const toggleSelectAllUser = (users, toggle) => {
  return users.map(item => {
    if (!canUserBeSelected(item)) {
      return item;
    }

    return {
      ...item,
      selected: toggle,
    };
  });
};

const toggleUserDeletingState = (users, id) => {
  return users.map(item => {
    if (item.id !== id) {
      return item;
    }

    return {
      ...item,
      deleting: !item.deleting,
    };
  });
};

const disableUsers = (users, ids) => {
  return users.map(item => {
    if (!ids.includes(item.id)) {
      return item;
    }
    return {
      ...item,
      status: 'inactive',
      selected: false,
    };
  });
};

const reactivateUser = (users, id) => {
  return users.map(item => {
    if (id !== item.id) {
      return item;
    }
    return {
      ...item,
      status: 'pending',
      selected: false,
    };
  });
};

const resetUsersPassword = (users, ids) => {
  return users.map(item => {
    if (!ids.includes(item.id)) {
      return item;
    }
    return {
      ...item,
      selected: false,
    };
  });
};

const getInitialState = () => {
  return {
    isLoading: false,
    data: [],
    error: false,
    params: {
      q: '',
      pagination: {
        page: 1,
        limit: 8,
      },
      sort: {},
      filters: [],
    },
  };
};

const initialState = getInitialState();

const users = (state = initialState, action) => {
  const types = {
    [GET_USERS_REQUEST]: () => ({
      ...state,
      isLoading: true,
      params: {
        ...state.params,
        ...action.params,
      },
    }),
    [GET_USERS_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      data: action.response,
      pageCount: action.pageCount,
    }),
    [GET_USERS_FAILURE]: () => ({
      ...state,
      isLoading: false,
      error: action.message,
    }),
    [DELETE_USER_REQUEST]: () => ({
      ...state,
      data: toggleUserDeletingState(state.data, action.id),
      modalLoading: true,
    }),
    [DELETE_USER_SUCCESS]: () => ({
      ...state,
      data: state.data.filter(item => item.id !== action.id),
      modalLoading: false,
    }),
    [DELETE_USER_FAILURE]: () => ({
      ...state,
      data: toggleUserDeletingState(state.data, action.id),
      modalLoading: false,
    }),
    [RESET_USERS_PARAMS]: () => {
      return initialState;
    },
    [RESET_USERS_FILTERS]: () => ({
      ...state,
      params: {
        ...state.params,
        filters: [],
      },
    }),
    [DISABLE_USERS_REQUEST]: () => ({
      // BULK DISABLE (In Bulk mode users can only be disabled - not enabled)
      ...state,
      modalLoading: true,
      error: false,
    }),
    [DISABLE_USERS_SUCCESS]: () => ({
      ...state,
      data: disableUsers(state.data, action.ids),
      isLoading: false,
      modalLoading: false,
    }),
    [DISABLE_USERS_FAILURE]: () => ({
      ...state,
      isLoading: false,
      modalLoading: false,
      message: action.message,
      error: true,
    }),
    [REACTIVATE_DISABLE_USER_REQUEST]: () => ({
      // SINGLE DISABLE
      ...state,
      modalLoading: true,
    }),
    [REACTIVATE_DISABLE_USER_SUCCESS]: () => ({
      ...state,
      data: reactivateUser(state.data, action.id),
      modalLoading: false,
    }),
    [REACTIVATE_DISABLE_USER_FAILURE]: () => ({
      ...state,
      modalLoading: false,
      error: true,
    }),
    [RESET_PASSWORDS_REQUEST]: () => ({
      ...state,
      modalLoading: true,
    }),
    [RESET_PASSWORDS_SUCCESS]: () => ({
      ...state,
      data: resetUsersPassword(state.data, action.ids),
      modalLoading: false,
    }),
    [RESET_PASSWORDS_FAILURE]: () => ({
      ...state,
      modalLoading: false,
      error: true,
    }),
    [TOGGLE_SELECT_USER]: () => ({
      ...state,
      data: toggleSelectUser(state.data, action.id),
    }),
    [TOGGLE_SELECT_ALL_USERS]: () => ({
      ...state,
      data: toggleSelectAllUser(state.data, action.toggle),
    }),
    [RESEND_USER_INVITE_REQUEST]: () => ({
      ...state,
      modalLoading: true,
    }),
    [RESEND_USER_INVITE_SUCCESS]: () => ({
      ...state,
      modalLoading: false,
    }),
    [RESEND_USER_INVITE_FAILURE]: () => ({
      ...state,
      message: action.message,
      error: true,
      modalLoading: false,
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default users;
