import React from 'react';

const Form = props => {
  const onSubmit = e => {
    e.preventDefault();
    props.onSubmit();
  };
  return (
    <form {...props} onSubmit={onSubmit}>
      {props.children}
    </form>
  );
};

export default Form;
