import React from 'react';
import { NotLoggedLayout, AuthLayout } from '../../layouts';
import { ResetPasswordContainer } from '../../containers';

const ResetPassword = props => (
  <NotLoggedLayout>
    <AuthLayout>
      <ResetPasswordContainer {...props} />
    </AuthLayout>
  </NotLoggedLayout>
);

export default ResetPassword;
