import React, { useState } from 'react';
import { Box, Icon, Tooltip } from 'hx-react-components';
import translations from '../../lang';
import { StyledSupportButton } from './SupportButton.styles';

const SupportButton = ({ onSupportButtonClick }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <React.Fragment>
      <Box>
        <Tooltip
          content={
            translations['en-US'].MODALS['createSupportTicket']
              .createNewTicketTooltip
          }
          position="left"
        >
          <StyledSupportButton
            isChild={true}
            index={0}
            collapsed={collapsed}
            onClick={onSupportButtonClick}
          >
            <Icon variant="light" icon="edit" size="small" />
          </StyledSupportButton>
        </Tooltip>
      </Box>
      <StyledSupportButton
        collapsed={collapsed}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        {collapsed ? (
          <Icon variant="light" icon="remove" size="small" />
        ) : (
          'Help'
        )}
      </StyledSupportButton>
    </React.Fragment>
  );
};

export default SupportButton;
