import React from 'react';
import { NotLoggedLayout, AuthLayout } from '../../layouts';
import { ForgotPasswordContainer } from '../../containers';

const ForgotPassword = props => (
  <NotLoggedLayout>
    <AuthLayout>
      <ForgotPasswordContainer {...props} />
    </AuthLayout>
  </NotLoggedLayout>
);

export default ForgotPassword;
