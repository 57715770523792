import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESET_CHANGE_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PWD_REACTIVATE_USER_REQUEST,
  RESET_PWD_REACTIVATE_USER_SUCCESS,
  RESET_PWD_REACTIVATE_USER_FAIULURE,
} from '../../constants/action-types';

const getInitialState = () => {
  return {
    isLoading: false,
    error: false,
    success: false,
  };
};

const initialState = getInitialState();

const changePassword = (state = initialState, action) => {
  const types = {
    [CHANGE_PASSWORD_REQUEST]: () => ({
      ...state,
      isLoading: true,
    }),
    [CHANGE_PASSWORD_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      error: false,
      success: true,
    }),
    [CHANGE_PASSWORD_FAILURE]: () => ({
      ...state,
      isLoading: false,
      message: action.message,
      error: true,
    }),
    [RESET_CHANGE_PASSWORD]: () => ({
      ...initialState,
    }),
    [FORGOT_PASSWORD_REQUEST]: () => ({
      ...state,
      isLoading: true,
    }),
    [FORGOT_PASSWORD_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      success: true,
      error: false,
    }),
    [FORGOT_PASSWORD_FAILURE]: () => ({
      ...state,
      isLoading: false,
      message: action.message,
      error: true,
    }),
    [RESET_PWD_REACTIVATE_USER_REQUEST]: () => ({
      ...state,
      isLoading: true,
    }),
    [RESET_PWD_REACTIVATE_USER_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      success: true,
      error: false,
    }),
    [RESET_PWD_REACTIVATE_USER_FAIULURE]: () => ({
      ...state,
      isLoading: false,
      message: action.message,
      error: true,
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default changePassword;
