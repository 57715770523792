import React from 'react';
import styled from 'styled-components';

const StyledNotLogged = styled.div`
  min-height: 100vh;
  width:100%;
  background-image: url('${process.env.PUBLIC_URL}/background.png');
  background-size:cover;
  background-color:#1A1A1F;
`;

const NotLoggedLayout = ({ children }) => (
  <React.Fragment>
    <StyledNotLogged>{children}</StyledNotLogged>
  </React.Fragment>
);

export default NotLoggedLayout;
