import { CALL_API } from '../middleware/api';

import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
} from '../constants/action-types';

// Get Projects
const fetchProducts = () => ({
  [CALL_API]: {
    types: [GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAILURE],
    endpoint: '/catalog/products?addons=true&fusions=true&internal=false', // Real APi '/products?addons=true&internal=true'
    method: 'GET',
    mock: false,
  },
});

export const requestProducts = () => dispatch => {
  return dispatch(fetchProducts());
};

const fetchMeProducts = () => ({
  [CALL_API]: {
    types: [
      'GET_ME_PRODUCTS_REQUEST',
      'GET_ME_PRODUCTS_SUCCESS',
      'GET_ME_PRODUCTS_FAILURE',
    ],
    endpoint: '/users/me/products?fusions=true',
    method: 'GET',
    mock: false,
  },
});

export const requestMeProducts = () => dispatch => {
  return dispatch(fetchMeProducts());
};
