import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Box } from 'hx-react-components';
import { VerticalCentered } from '../components';

const AuthLayout = ({ children }) => (
  <>
    <VerticalCentered>
      <Container style={{ width: '500px', padding: '16px 32px' }}>
        <Row halign="center">
          <Col span={'auto'}>
            <Link to="/">
              <img
                alt="logo"
                width="305px"
                height="50px"
                src={`${process.env.PUBLIC_URL}/logo-white.svg`}
              />
            </Link>
          </Col>
        </Row>
      </Container>
      <Container style={{ width: '500px' }}>
        <Box>{children}</Box>
      </Container>
    </VerticalCentered>
  </>
);

export default AuthLayout;
