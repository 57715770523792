const COGNITO = {
  develop: {
    Auth: {
      REGION: 'us-west-2',
      USER_POOL_ID: 'us-west-2_I6tI3dFWh',
      APP_CLIENT_ID: '4bld4mvg6j804r6pa29uc66drl',
    },
  },
  staging: {
    Auth: {
      REGION: 'us-west-2',
      USER_POOL_ID: 'us-west-2_156URAqKX',
      APP_CLIENT_ID: 'ddht5bp9fsedldb636l1amaj6',
    },
  },
  production: {
    Auth: {
      REGION: 'us-west-2',
      USER_POOL_ID: 'us-west-2_VkvfwpB1L',
      APP_CLIENT_ID: '5v3fub6h9ev7vb8loanu7me59n',
    },
  },
};

export default COGNITO[process.env.REACT_APP_API_ENV];
