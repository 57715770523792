import React from 'react';
import { Modal, ListGroup, Button } from 'hx-react-components';

const ConfirmModal = ({ onConfirm, onCancel, confirmText, ...rest }) => {
  const handleConfirm = () => {
    onCancel();
    onConfirm();
  };

  const footer = (
    <ListGroup
      direction="row"
      items={[
        <Button size="small" onClick={handleConfirm}>
          Confirm
        </Button>,
        <Button size="small" variant={'outlined'} onClick={() => onCancel()}>
          Cancel
        </Button>,
      ]}
    />
  );
  return (
    <Modal title="Confirm" footer={footer} {...rest}>
      {confirmText}
    </Modal>
  );
};

export default ConfirmModal;
