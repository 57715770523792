import {
  GET_ME_REQUEST,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
  UPDTAE_AVATAR_REQUEST,
  UPDTAE_AVATAR_SUCCESS,
  UPDTAE_AVATAR_FAILURE,
  UPDTAE_USER_FULLNAME_REQUEST,
  UPDTAE_USER_FULLNAME_SUCCESS,
  UPDTAE_USER_FULLNAME_FAILURE,
  TOGGLE_CHANGE_ME_FULLNAME_POPOVER,
  LOGOUT_SUCCESS,
} from '../../constants/action-types';

const getInitialState = () => {
  return {
    isLoading: false,
    popoverLoading: false,
    user: {},
    error: false,
  };
};

const initialState = getInitialState();

const me = (state = initialState, action) => {
  const types = {
    [GET_ME_REQUEST]: () => ({
      ...state,
      isLoading: true,
    }),
    [GET_ME_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      error: false,
      user: action.response,
    }),
    [GET_ME_FAILURE]: () => ({
      ...state,
      isLoading: false,
      error: true,
      message: action.message,
    }),
    [LOGOUT_SUCCESS]: () => ({
      ...initialState,
    }),
    [UPDTAE_AVATAR_REQUEST]: () => ({
      ...state,
      modalLoading: true,
      error: false,
    }),
    [UPDTAE_AVATAR_SUCCESS]: () => ({
      ...state,
      modalLoading: false,
      user: {
        ...state.user,
        avatar: action.avatar,
      },
    }),
    [UPDTAE_AVATAR_FAILURE]: () => ({
      ...state,
      modalLoading: false,
      error: true,
      message: action.message,
    }),
    [UPDTAE_USER_FULLNAME_REQUEST]: () => ({
      ...state,
      popoverLoading: true,
    }),
    [UPDTAE_USER_FULLNAME_SUCCESS]: () => ({
      ...state,
      popoverLoading: false,
      user: {
        ...state.user,
        name: action.name,
        surname: action.surname,
      },
    }),
    [UPDTAE_USER_FULLNAME_FAILURE]: () => ({
      ...state,
      popoverLoading: false,
    }),
    [TOGGLE_CHANGE_ME_FULLNAME_POPOVER]: () => ({
      ...state,
      changeNamePopoverOpen: action.open,
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default me;
