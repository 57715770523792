import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_CHECK_REQUEST,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESET_AUTH,
} from '../../constants/action-types';

import { AuthHelperMethods } from '../../utils/';
const { getDecodedToken } = AuthHelperMethods;

const getInitialState = () => {
  return {
    init: false,
    isLoading: false,
    token: null,
    isLogged: false,
    decodedToken: null,
    message: '',
    error: false,
  };
};

const initialState = getInitialState();

const auth = (state = initialState, action) => {
  const types = {
    [AUTH_CHECK_REQUEST]: () => ({
      ...state,
    }),
    [AUTH_CHECK_SUCCESS]: () => ({
      ...state,
      init: true,
      isLogged: true,
      token: action.token,
      accessToken: action.accessToken,
      refreshToken: action.refreshToken,
      decodedToken: getDecodedToken(action.token),
    }),
    [AUTH_CHECK_FAILURE]: () => ({
      ...state,
      init: true,
      isLogged: false,
    }),
    [AUTH_REQUEST]: () => ({
      ...state,
      init: true,
      isLoading: true,
    }),
    [AUTH_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      token: action.token,
      accessToken: action.accessToken,
      refreshToken: action.refreshToken,
      error: action.error,
      message: '',
      isLogged: true,
      decodedToken: getDecodedToken(action.token),
    }),
    [AUTH_FAILURE]: () => ({
      ...state,
      isLoading: false,
      token: null,
      error: action.error,
      message: action.message,
      isLogged: false,
      decodedToken: null,
    }),
    [LOGOUT_REQUEST]: () => ({
      ...state,
      isLoading: true,
    }),
    [LOGOUT_SUCCESS]: () => ({
      ...state,
      init: false,
      isLoading: false,
      token: null,
      accessToken: null,
      refreshToken: null,
      isLogged: false,
      decodedToken: null,
      error: false,
      hasLoggedOut: true,
    }),
    [RESET_AUTH]: () => ({
      ...state,
      message: '',
      error: false,
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default auth;
