import React from 'react';
import styled from 'styled-components';
import { Text, Anchor } from 'hx-react-components';
import { PRIVACY_URL, LEGAL_URL } from '../../constants/config';

const Footer = styled.div`
  height: 56px;
  width: 100%;
  bottom: 0;
  position: fixed;
  background: rgb(35, 35, 35);
`;

const FooterRow = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const FooterCol = styled.div`
  padding-left: 50px;

  & a {
    color: white;
    font-size: 12px;
  }
`;

const FooterContainer = () => {
  const now = new Date();
  return (
    <Footer>
      <FooterRow>
        <FooterCol>
          <Text variant="light" size="small" as="span">
            © {now.getFullYear()} TelmarHelixa. All rights reserved
          </Text>
        </FooterCol>
        <FooterCol>
          <Anchor href={LEGAL_URL} target="_blank">
            Legal
          </Anchor>
        </FooterCol>
        <FooterCol>
          <Anchor href={PRIVACY_URL} target="_blank">
            Privacy
          </Anchor>
        </FooterCol>
      </FooterRow>
    </Footer>
  );
};

export default FooterContainer;
