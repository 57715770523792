import React from 'react';
import {
  Panel,
  ListGroup,
  Button,
  Text,
  Loading,
  Row,
  Col,
} from 'hx-react-components';
import { withRouter } from 'react-router-dom';

const getFooter = (showMoreInfo, onMoreInfoClick) => {
  if (!showMoreInfo) {
    return false;
  }
  return (
    <Row valign="middle">
      <Col span="auto">
        <Button variant="outlined" size="small" onClick={onMoreInfoClick}>
          more info
        </Button>
      </Col>
    </Row>
  );
};
const getPlansList = data => {
  const plansList = data.map(item => (
    <React.Fragment>
      <Row valign="middle">
        <Col span="auto">
          <Text tag="span" weight="bold" size="xbody">
            {item.name}
          </Text>{' '}
          <Text tag="span" size="xbody" variant="dark_light">
            Licences: {item.licenses.total}
          </Text>
          {' - '}
          <Text tag="span" size="xbody" variant="dark_light">
            In use: {item.licenses.total - item.licenses.available}
          </Text>
        </Col>
      </Row>
    </React.Fragment>
  ));

  return <ListGroup items={plansList} />;
};

const UserProductsPanel = ({ userProducts, showMoreInfo, history }) => {
  const { isLoading, data } = userProducts;

  const onMoreInfoClick = () => {
    history.push('/my-products');
  };

  const footer = getFooter(showMoreInfo, onMoreInfoClick);

  return (
    <Panel
      title={!isLoading ? 'My Products' : ''}
      footer={!isLoading && footer ? footer : null}
    >
      <Loading isLoading={isLoading}>{getPlansList(data)}</Loading>
    </Panel>
  );
};

export default withRouter(UserProductsPanel);
