import merge from 'deepmerge';

const filterIsInArray = (filter, filters) =>
  filters.filter(item => item.field === filter.field).length > 0;

const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

const checkDateIsNull = value => {
  if (value['from'] === null && value['to'] === null) {
    return true;
  }
};

const mergeFilters = (currentFilters, nextFilters) => {
  const nextFilter = nextFilters[0];

  if (
    nextFilter.value === 'ALL' ||
    !nextFilter.value ||
    nextFilter.value.length === 0 ||
    checkDateIsNull(nextFilter.value)
  ) {
    return currentFilters.filter(item => item.field !== nextFilter.field);
  }

  if (currentFilters.length === 0) {
    currentFilters.push(nextFilter);
    return currentFilters;
  }

  const isInArray = filterIsInArray(nextFilter, currentFilters);

  let result = [];

  if (!isInArray) {
    result = [...currentFilters];
    result.push(nextFilter);
    return result;
  }

  result = currentFilters.map(item => {
    if (item.field === nextFilter.field) {
      return merge(item, nextFilter, {
        arrayMerge: Array.isArray(nextFilter.value) ? overwriteMerge : false,
      });
    }
    return item;
  });

  return result;
};

const getParams = (currentParams, nextParams) => {
  let returnParams = currentParams;
  if (nextParams) {
    const mergeOptions = {
      customMerge: key => {
        if (key === 'filters') {
          return mergeFilters;
        }
      },
    };
    returnParams = merge(currentParams, nextParams, mergeOptions);
  }

  return returnParams;
};

export { getParams };
