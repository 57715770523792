import {
  CHECK_VALIDATION_TOKEN_REQUEST,
  CHECK_VALIDATION_TOKEN_VALID,
  CHECK_VALIDATION_TOKEN_FAILURE,
} from '../../constants/action-types';

const getInitialState = () => {
  return {
    isLoading: true,
    error: false,
  };
};

const initialState = getInitialState();

const checkValidationToken = (state = initialState, action) => {
  const types = {
    [CHECK_VALIDATION_TOKEN_REQUEST]: () => ({
      ...state,
    }),
    [CHECK_VALIDATION_TOKEN_VALID]: () => ({
      ...state,
      isLoading: false,
      valid: true,
    }),
    [CHECK_VALIDATION_TOKEN_FAILURE]: () => ({
      ...state,
      isLoading: false,
      valid: false,
      message: action.message,
      error: action.error,
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default checkValidationToken;
