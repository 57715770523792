import decode from 'jwt-decode';
import docCookies from 'doc-cookies';
import Cookies from 'js-cookie';

import { getCurrentSession, getUserScopes } from './CognitoHelperMethods';
import { getProductLink } from './misc';

const isLogged = async () => {
  const token = await this.getToken();
  const isTokenValid = this.isTokenValid(token);
  const isTokenExpired = this.isTokenExpired(token);
  return isTokenValid && !isTokenExpired;
};

const isTokenValid = token => {
  try {
    const decoded = this.getDecodedToken(token);
    if (decoded.exp) {
      return true;
    }
  } catch (err) {
    return false;
  }
};

const isTokenExpired = token => {
  try {
    const decoded = this.getDecodedToken(token);
    if (decoded.exp * 1000 < Date.now()) {
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
};

const getDecodedToken = token => {
  let decodedToken = {};
  try {
    const decoded = decode(token);
    decodedToken = {
      ...decoded,
      scopes: getUserScopes(decoded),
      products: JSON.parse(decoded.products),
    };
    return decodedToken;
  } catch (error) {
    console.warn(error);
    return {};
  }
};

const storeTokens = ({ refreshToken, idToken, accessToken }) => {
  if (refreshToken) {
    Cookies.set('refreshToken', refreshToken, { domain: '.helixa.ai' });
  }
  if (idToken) {
    Cookies.set('idToken', idToken, { domain: '.helixa.ai' });
  }
  if (accessToken) {
    Cookies.set('accessToken', accessToken, { domain: '.helixa.ai' });
  }
};

const redirectSingleSignOn = app_id => {
  const link = getProductLink(app_id);
  window.location.href = `//${decodeURIComponent(link)}`;
};

const getAccessToken = async () => {
  const session = await getCurrentSession();
  const { accessToken } = session;
  return accessToken ? accessToken.jwtToken : null;
};

const getIdToken = async () => {
  const session = await getCurrentSession();
  const { idToken } = session;
  return idToken ? idToken.jwtToken : null;
};

const setCookieDomain = (idToken, accessToken, expiration) => {
  docCookies.setItem(
    'HELIXA_ID_TOKEN',
    idToken,
    expiration,
    '/',
    '.helixa.ai',
    false
  );
  docCookies.setItem(
    'HELIXA_ACCESS_TOKEN',
    accessToken,
    expiration,
    '/',
    '.helixa.ai',
    false
  );
};

const removeCookieDomain = () => {
  docCookies.removeItem('DEVELOP_HELIXA_REFESH_TOKEN', '/', '.helixa.ai');
};

export {
  isLogged,
  isTokenValid,
  isTokenExpired,
  getDecodedToken,
  redirectSingleSignOn,
  getAccessToken,
  getIdToken,
  setCookieDomain,
  removeCookieDomain,
  storeTokens,
};
