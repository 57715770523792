import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Overlay } from 'hx-react-components';

import { default as modalTypes } from '../../modals';

const MODAL_TYPES = {
  ...modalTypes,
};

class ModalRootContainer extends Component {
  state = {
    modalIsOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        modalIsOpen: this.props.modalProps.isOpen,
      });
    }
  }

  closeModal = () => {
    this.setState({
      ...this.state,
      modalIsOpen: false,
    });
  };

  render() {
    if (!this.props.modalType) {
      return null;
    }

    const { modalProps, modalType } = this.props;

    const SpecifiedModal = MODAL_TYPES[modalType];
    const { modalIsOpen } = this.state;

    if (!SpecifiedModal) {
      return null;
    }

    return (
      <Suspense fallback={<div />}>
        <Overlay
          isOpen={modalIsOpen}
          position={modalProps.position}
          hasScrim={modalProps.hasScrim}
          handleModalOpen={this.closeModal}
        >
          <SpecifiedModal {...modalProps} handleClose={this.closeModal} />
        </Overlay>
      </Suspense>
    );
  }
}

const mapStateToProps = state => ({
  ...state.modals,
});

export default connect(
  mapStateToProps,
  null
)(ModalRootContainer);
