import { CALL_API } from '../middleware/api';

import {
  GET_COMPANY_DETAIL_REQUEST,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAILURE,
} from '../constants/action-types';

import { getCompanyId } from '../utils/CognitoHelperMethods';

const fetchCompanyDetail = companyId => {
  return {
    [CALL_API]: {
      types: [
        GET_COMPANY_DETAIL_REQUEST,
        GET_COMPANY_DETAIL_SUCCESS,
        GET_COMPANY_DETAIL_FAILURE,
      ],
      endpoint: `/companies/${companyId}`,
      method: 'GET',
      mock: false,
    },
  };
};

export const requestCompanyDetail = () => (dispatch, getState) => {
  const { decodedToken } = getState().auth;
  const companyId = getCompanyId(decodedToken);
  return dispatch(fetchCompanyDetail(companyId));
};
