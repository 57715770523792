import React, { Suspense, lazy, useState, useEffect, useMemo } from 'react';
import { AccountLayout } from '../../layouts';
import { SideMenuContainer } from '../../containers';
import { Redirect, Switch, Route } from 'react-router-dom';

import { AccountSettingsHeader } from '../../containers';
import { connect } from 'react-redux';
import { requestCompanyDetail } from '../../actions/CompanyActions';

const AccountSettingsOverviewLazy = lazy(() =>
  import('../../containers/AccountSettingsOverview/')
);

const AccountSettingsUsersLazy = lazy(() =>
  import('../../containers/AccountSettingsUsers')
);

const AccountSettingsMyProductsLazy = lazy(() =>
  import('../../containers/AccountSettingsMyProducts')
);

const AccountSettingsEditUsersLazy = lazy(() =>
  import('../../containers/AccountSettingsEditUsers')
);

const AccountSettingsHomeLazy = lazy(() =>
  import('../../containers/HomeContainer')
);

const checkAuth = (userScopes, scopesRequired) => {
  return scopesRequired.every(
    scope => userScopes && userScopes.includes(scope)
  );
};

const getAuthorizedPaths = (userScopes, subpaths, isUserSectionDisabled) => {
  if (subpaths.length > 0) {
    return subpaths.filter(path => {
      if (isUserSectionDisabled && path.path === '/users') {
        return false;
      }
      return checkAuth(userScopes, path.requiredScopes);
    });
  }

  return [];
};

const AccountSettings = ({
  decodedToken,
  subpaths = [],
  requestCompanyDetail,
  company,
  history,
}) => {
  const [isUserSectionDisabled, setIsUserSectionDisabled] = useState(false);

  useEffect(() => {
    requestCompanyDetail();
  }, []);

  useEffect(() => {
    setIsUserSectionDisabled(
      company && company.data ? company.data.user_section === false : false
    );
  }, [company]);

  if (!decodedToken) {
    return null;
  }
  const authorizedPaths = getAuthorizedPaths(
    decodedToken.scopes,
    subpaths,
    isUserSectionDisabled
  );

  const isHomePage = useMemo(() => {
    return history.location.pathname === '/';
  }, [history]);

  return (
    <AccountLayout
      left={!isHomePage && <SideMenuContainer nav={authorizedPaths} />}
      top={
        <AccountSettingsHeader isUserSectionDisabled={isUserSectionDisabled} />
      }
    >
      <Suspense fallback={<div />}>
        <Switch>
          <Route
            exact
            path="/account"
            component={AccountSettingsOverviewLazy}
          />
          {isUserSectionDisabled ? (
            <Redirect exact from="/users" to="/account" />
          ) : (
            <Route exact path="/users" component={AccountSettingsUsersLazy} />
          )}
          <Route
            exact
            path="/users/:id"
            component={AccountSettingsEditUsersLazy}
          />
          <Route
            exact
            path="/my-products"
            component={AccountSettingsMyProductsLazy}
          />
          <Route exact path="/" component={AccountSettingsHomeLazy} />
          <Redirect
            exact
            from="/account-settings/my-products"
            to="/my-products"
          />
          <Redirect exact from="/account-settings/*" to="/account" />
        </Switch>
      </Suspense>
    </AccountLayout>
  );
};

const mapDispatchToProps = {
  requestCompanyDetail,
};

const mapStateToProps = state => ({
  company: state.company,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettings);
