import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Text,
  Loading,
  Row,
  Col,
  Anchor,
  Feedback,
  Box,
} from 'hx-react-components';
import { Link } from 'react-router-dom';
import { PasswordFormItem, Form } from '../';
import { validateEmail } from '../../utils';
import { LanguageConsumer } from '../../contexts';
import translations from '../../lang';
import styled from 'styled-components';
import InputFormItem from '../InputFormItem';

const FormButton = styled.div`
  & button,
  a {
    width: 100%;
    background-color: #232323;
    border-color: #232323;
    border-radius: 5px;

    &:hover {
      background-color: #2d2d2d;
      border-color: #2d2d2d;
    }

    &[disabled] {
      background-color: #d7d7d7;
      border-color: #d7d7d7;
    }
  }
`;

const FormContainer = styled.div`
  padding: 48px 30px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin: 1rem 0;
`;

class Login extends Component {
  state = {
    email: '',
    password: '',
    touched: {},
  };

  handleOnFieldChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleOnFieldblur = e => {
    this.setState({
      ...this.state,
      touched: {
        ...this.state.touched,
        [e.target.name]: true,
      },
    });
  };

  getValidation = (email, password) => ({
    email: validateEmail(email),
    password: password.length > 0,
  });

  handleOnLoginSubmit = () => {
    const { email, password } = this.state;
    this.props.requestLogin(email, password);
  };

  canSubmit = validation => {
    return Object.values(validation).every(field => field);
  };

  render() {
    const { email, password, touched } = this.state;
    const { isLoading, error, message } = this.props.auth;
    const validation = this.getValidation(email, password);
    const canSubmit = this.canSubmit(validation);
    return (
      <LanguageConsumer>
        {lang => (
          <Box minHeight="200px">
            <Loading hideMode isLoading={isLoading}>
              <FormContainer>
                <Row halign="center">
                  <Col span={'auto'}>
                    <Text variant="dark" size="xheadline">
                      Log in to Discover!
                    </Text>
                  </Col>
                </Row>
                {error && (
                  <Box mT="large" mB="large">
                    <Feedback message={message} status="error" />
                  </Box>
                )}
                <Form onSubmit={this.handleOnLoginSubmit}>
                  <Box mT="medium" mB="medium">
                    <InputFormItem
                      label={'Email'}
                      message="Email is not valid"
                      error={touched['email'] && !validation['email']}
                      type="email"
                      name="email"
                      autoFocus
                      tabIndex={1}
                      value={email}
                      onChange={this.handleOnFieldChange}
                      onBlur={this.handleOnFieldblur}
                    />
                  </Box>
                  <Box mB="medium">
                    <PasswordFormItem
                      tabIndex={2}
                      formItemVariant="dark_lighter"
                      placeholder="Password"
                      name="password"
                      value={password}
                      label={'Password'}
                      error={touched['password'] && !validation['password']}
                      errorMessage="Password cannot be empty"
                      onPasswordChange={this.handleOnFieldChange}
                      onBlur={this.handleOnFieldblur}
                    />
                  </Box>
                  <Row halign="center">
                    <Col span="auto" style={{ width: '100%' }}>
                      <FormButton>
                        <Button type="submit" disabled={!canSubmit}>
                          Login
                        </Button>
                      </FormButton>
                    </Col>
                  </Row>
                  <Row halign="center">
                    <Col span={'auto'}>
                      <Box mT="small">
                        <Link to="/forgot-password">
                          <Anchor as="span" variant="dark">
                            {translations[lang].LOGIN.FORGOT_PASSWORD_QUESTION}
                          </Anchor>
                        </Link>
                      </Box>
                    </Col>
                  </Row>
                </Form>
              </FormContainer>
            </Loading>
          </Box>
        )}
      </LanguageConsumer>
    );
  }
}

Login.propTypes = {
  requestLogin: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
};

export default Login;
