import { format } from 'date-fns';
import { PRODUCT_LINK } from '../constants/config';

const checkActiveRoute = (route, path) => {
  return path.includes(route);
};

const getFormattedDate = (date, dateFormat = 'MM/DD/YYYY') => {
  const dateObject = new Date(date * 1000);
  return format(dateObject, dateFormat);
};

const ammountFormatter = ammount => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const formattedAmmount = formatter.format(ammount);
  return formattedAmmount;
};

const getInitials = user => {
  if (!user || !user.email) {
    return '';
  }

  if (user.name && user.surname) {
    return `${user.name.charAt(0)}${user.surname.charAt(0)}`;
  }

  return `${user.email.substring(0, 2)}`;
};

const getFullName = user => {
  if (user.name && user.surname) {
    return `${user.name} ${user.surname}`;
  }

  return null;
};

const newPasswordRules = [
  {
    label: 'One lowercase character',
    regex: '[a-z]{1,}',
  },
  {
    label: 'One uppercase character',
    regex: '[A-Z]{1,}',
  },
  {
    label: 'One number',
    regex: '[0-9]{1,}',
  },
  {
    label: '8 characters minimum',
    regex: '.{8,}',
  },
  {
    label: 'One special character',
    regex: '[!@#$%^&*(),.?":;{}_|<>=/+-]{1,}',
  },
];

const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isFormValid = validations => {
  const formValid = Object.keys(validations).every(key => validations[key]);
  return formValid;
};

const areLoading = loadings => {
  return Object.keys(loadings).every(key => !loadings[key]);
};

const objectIsNull = object => {
  const isEmpty = Object.values(object).every(x => x === null);
  return isEmpty;
};

const getFilterStatus = ({ assets, field, value, position }) => {
  const { filters } = assets.params;

  const filter = filters.filter(item => item.field === field)[0];

  if (!filter) {
    if (position !== undefined) {
      return null;
    }
    return false;
  }

  if (position !== undefined) {
    return parseInt(filter.value[position]);
  }

  if (typeof filter.value === 'object') {
    return filter && filter.value.includes(value);
  }

  return filter && filter.value === value.toString();
};

const isUndefined = item => {
  return item === undefined;
};

const isEmpty = arr => {
  return arr.length === 0;
};

const getAssignedIds = arr => {
  return arr
    .filter(item => {
      return item.assigned;
    })
    .map(item => {
      return item.id;
    });
};

const getProductLink = product_slug => {
  return PRODUCT_LINK[process.env.REACT_APP_API_ENV][product_slug];
};

export {
  checkActiveRoute,
  ammountFormatter,
  getFormattedDate,
  newPasswordRules,
  getInitials,
  validateEmail,
  isFormValid,
  areLoading,
  objectIsNull,
  getFilterStatus,
  isUndefined,
  getAssignedIds,
  getFullName,
  isEmpty,
  getProductLink,
};
