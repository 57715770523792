import { CALL_API } from '../middleware/api';
import { objectIsNull } from './misc';

const generalFetchCall = ({
  params,
  paramsUrl,
  method = 'GET',
  request,
  success,
  failure,
  mock,
}) => {
  const { q, pagination, sort, filters } = params;

  let baseUrl = paramsUrl;
  let urlParams = [];

  if (q || pagination || sort) {
    baseUrl += '?';
  }

  if (pagination) {
    urlParams.push(`page=${pagination.page}&limit=${pagination.limit}`);
  }

  if (q) {
    urlParams.push(`q=${q}`);
  }

  if (sort.orderBy && sort.order) {
    urlParams.push(
      `sort_by=${sort.order === 'desc' ? '-' : ''}${sort.orderBy}`
    );
  }

  if (filters.length > 0) {
    const filter_string = filters
      .map(filter => {
        if (filter.value['from'] && !objectIsNull(filter.value)) {
          if (filter.value['to']) {
            return `${filter.field}=${filter.value['from']},${
              filter.value['to']
            }`;
          }
          return `${filter.field}=${filter.value['from']}`;
        }

        return `${filter.field}=${filter.value}`;
      })
      .join('&');

    urlParams.push(filter_string);
  }

  const url = baseUrl + urlParams.join('&');

  return {
    [CALL_API]: {
      types: [request, success, failure],
      endpoint: url,
      method: method,
      mock,
    },
    params: {
      q,
      pagination,
      sort,
      filters,
    },
  };
};

export { generalFetchCall };
