import React from 'react';
import { Container, Row, Col, Box, Text, Icon } from 'hx-react-components';
import {
  MinResolutionContainer,
  LogoContainer,
  VerticalCentered,
} from './StyledMinResolutionScreen';

const MinResolutionScreen = () => (
  <MinResolutionContainer>
    <LogoContainer>
      <Container size="xsmall">
        <Row halign="center">
          <Col span={'auto'}>
            <img
              alt="logo"
              height={'60px'}
              src={`${process.env.PUBLIC_URL}/logo-white.svg`}
            />
          </Col>
        </Row>
      </Container>
    </LogoContainer>
    <VerticalCentered>
      <Container size="xsmall">
        <Box mB="xxlarge" mT="xxlarge">
          <Text
            variant="light_darker_1"
            size="headline"
            align="center"
            lettercase="uppercase"
          >
            OOPS!
          </Text>
          <Box mB="large" mT="large">
            <Text variant="light" size="body" align="center">
              Your browser screen resolution is too small,
              <br />
              please widen your browser window.
            </Text>
          </Box>
          <Icon
            icon="resize"
            size="xxlarge"
            variant="light"
            style={{ justifyContent: 'center' }}
          />
          <Box mB="large" mT="large">
            <Text variant="light" size="xbody" align="center">
              (min resolution: 1024x768)
            </Text>
          </Box>
        </Box>
      </Container>
    </VerticalCentered>
  </MinResolutionContainer>
);

export default MinResolutionScreen;
