import { AccountSettings } from '../routes';

const account = [
  {
    label: 'Account & Settings',
    path: '/',
    private: true,
    inMenu: false,
    exact: false,
    component: AccountSettings,
    routes: [
      {
        label: 'Account overview',
        path: '/account',
        private: true,
        requiredScopes: ['users-admin'],
        redirectPath: '/login',
        inMenu: true,
        exact: true,
        icon: 'show',
      },
      {
        label: 'Users',
        path: '/users',
        private: true,
        redirectPath: '/login',
        requiredScopes: ['users-admin'],
        inMenu: true,
        exact: false,
        icon: 'users',
      },
      {
        label: 'My products',
        path: '/my-products',
        private: true,
        redirectPath: '/login',
        requiredScopes: ['users-admin'],
        inMenu: true,
        exact: true,
        icon: 'products&services',
      },
    ],
  },
];

export default account;
