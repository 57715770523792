import React from 'react';
import styled from 'styled-components';
import { Toast, Button } from 'hx-react-components';

const transitionName = `fadeInDownUp`;

const StyledToastAnimate = styled.div`
  margin-bottom: 10px;

  &.${transitionName}-enter, &.${transitionName}-appear {
    opacity: 0.01;
    visibility: hidden;
    transform: translate(0, 20px);
  }

  &.${transitionName}-enter-active, &.${transitionName}-appear-active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    transition: all 0.5s ease;
  }

  &.${transitionName}-leave {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    transition: all 0.5s ease;
  }

  &.${transitionName}-leave-active {
    opacity: 0.01;
    visibility: hidden;
    transform: translate(0, 20px);
  }
`;

const ToastItem = ({
  toastId,
  status,
  message,
  closeToast,
  buttonText,
  buttonAction,
}) => {
  const button =
    buttonText && buttonAction ? (
      <Button onClick={buttonAction} variant="subtle" size="small">
        {buttonText}
      </Button>
    ) : null;

  const toast = (
    <Toast
      isVisible
      status={status}
      message={message}
      button={button}
      onCloseClick={() => closeToast(toastId)}
    />
  );

  return <StyledToastAnimate>{toast}</StyledToastAnimate>;
};

export default ToastItem;
