import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Button, Row, Col, Text, Box } from 'hx-react-components';
import { ProductDates, ProductLicences } from '../../components';
import ProductIcon from '../ProductIcon';

const ProductBox = ({ product, onHandleOpenModal }) => (
  <Box mB="large">
    <Panel
      footer={
        <Button
          variant="outlined"
          size="small"
          data-test-selector="hx-test-products-modal-opener"
          onClick={() => onHandleOpenModal(product)}
        >
          more info
        </Button>
      }
    >
      <Row halign="between">
        <Col span={9}>
          <Box mB="xsmall">
            <Text tag="h3" size="xheadline" weight="semi-bold">
              {product.name}
            </Text>
          </Box>
          <ProductLicences licences={product.licenses} />
          <ProductDates
            activationDate={product.activation_date}
            expirationDate={product.expiration_date}
          />
        </Col>
        <Col span="auto">
          <ProductIcon product={product} />
        </Col>
      </Row>
    </Panel>
  </Box>
);

ProductBox.defaultProps = {
  products: {},
};

ProductBox.propTypes = {
  products: PropTypes.object.isRequired,
  onHandleOpenModal: PropTypes.func.isRequired,
};

export default ProductBox;
