import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { resetGenericError } from '../../actions/GenericError';
import { hideModal } from '../../actions/ModalActions';

class GenericErrorContainer extends Component {
  componentDidUpdate() {
    const isErrorPage = this.props.history.location.pathname === '/error';

    if (this.props.genericError.redirect && !isErrorPage) {
      this.props.hideModal();
      this.props.history.push('/error');
      this.props.resetGenericError();
    }
  }
  render() {
    return '';
  }
}

const mapDispatchToProps = {
  resetGenericError,
  hideModal,
};

const mapStateToProps = state => ({
  genericError: state.genericError,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GenericErrorContainer)
);
