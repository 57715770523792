import { SHOW_MODAL, HIDE_MODAL } from '../../constants/action-types';

const getInitialState = () => {
  return {
    modalType: null,
    modalProps: {},
  };
};

const initialState = getInitialState();

const modals = (state = initialState, action) => {
  const types = {
    [SHOW_MODAL]: () => ({
      modalProps: action.modalProps,
      modalType: action.modalType,
      type: action.type,
    }),
    [HIDE_MODAL]: () => initialState,
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default modals;
