import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { ListGroup, ListItem } from 'hx-react-components';

import { checkActiveRoute } from '../../utils';
import { sendEvent } from '../../actions/EventsActions';

class SideMenuContainer extends Component {
  render() {
    const { nav, location, history } = this.props;

    const menu = nav.map((item, index) => {
      const isActive = checkActiveRoute(item.path, location.pathname);
      return item.inMenu ? (
        <ListItem
          iconLeft={item.icon}
          text={item.label}
          data-test-selector={
            isActive ? 'cy-menu__item--selected' : 'cy-menu__item'
          }
          key={index}
          isActive={isActive}
          onClick={() => {
            sendEvent(`Account Settings - Go to ${item.label}`);
            history.push(item.path);
          }}
        />
      ) : null;
    });

    return (
      <div>
        <ListGroup size="xxsmall" items={menu} />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideMenuContainer)
);
