import { CALL_API } from '../middleware/api';
import { Auth } from 'aws-amplify';

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_CHECK_REQUEST,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_FAILURE,
  RESET_CHANGE_PASSWORD,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHECK_VALIDATION_TOKEN_REQUEST,
  CHECK_VALIDATION_TOKEN_SUCCESS,
  CHECK_VALIDATION_TOKEN_FAILURE,
  CHECK_VALIDATION_TOKEN_VALID,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  RESET_AUTH,
  RESET_PASSWORDS_REQUEST,
  RESET_PASSWORDS_SUCCESS,
  RESET_PASSWORDS_FAILURE,
  RESET_PWD_REACTIVATE_USER_REQUEST,
  RESET_PWD_REACTIVATE_USER_SUCCESS,
  RESET_PWD_REACTIVATE_USER_FAIULURE,
} from '../constants/action-types';

import { addToast } from '../actions/ToastActions';
import { hideModal } from '../actions/ModalActions';

import { validateApiResponse } from '../utils';
import { AuthHelperMethods } from '../utils/';

import {
  signIn,
  signOut,
  getCurrentSession,
  getIdToken,
  getAccessToken,
  getRefreshToken,
} from '../utils/CognitoHelperMethods';
import { sendEvent } from './EventsActions';

const { redirectSingleSignOn } = AuthHelperMethods;

export const requestLogin = (username, password) => dispatch => {
  dispatch({
    type: AUTH_REQUEST,
  });

  signIn(username, password)
    .then(res => {
      if (typeof res === 'string') {
        return dispatch({
          type: AUTH_FAILURE,
          message: res,
          error: true,
        });
      }

      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Auth.completeNewPassword(res, password);
      }

      const id_token = getIdToken(res);
      const access_token = getAccessToken(res);
      const refresh_token = getRefreshToken(res);

      sendEvent('Login');
      return dispatch({
        type: AUTH_SUCCESS,
        token: id_token,
        accessToken: access_token,
        refreshToken: refresh_token,
      });
    })
    .catch(err => {
      return dispatch({
        type: AUTH_FAILURE,
        message: err.message,
        error: true,
      });
    });
};

export const initialAuth = () => dispatch => {
  dispatch({
    type: AUTH_CHECK_REQUEST,
  });

  getCurrentSession().then(res => {
    if (typeof res !== 'string') {
      const id_token = getIdToken(res);
      const access_token = getAccessToken(res);
      const refresh_token = getRefreshToken(res);

      if (id_token && access_token && refresh_token) {
        return dispatch({
          type: AUTH_CHECK_SUCCESS,
          token: id_token,
          accessToken: access_token,
          refreshToken: refresh_token,
        });
      }
    }

    return dispatch({
      type: AUTH_CHECK_FAILURE,
    });
  });
};

export const redirectWithTokens = redirectSingleSignOn;

const fetchRequestSignUp = (email, name, surname, code, password) => ({
  [CALL_API]: {
    types: [SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE],
    endpoint: `/auth/sign_up`,
    method: 'POST',
    body: JSON.stringify({ email, name, surname, code, password }),
    mock: false,
  },
});

export const requestRegistration = (
  email,
  name,
  surname,
  code,
  password
) => dispatch => {
  dispatch({
    type: AUTH_REQUEST,
    error: false,
  });

  return dispatch(
    fetchRequestSignUp(email, name, surname, code, password)
  ).then(res => {
    if (validateApiResponse(res)) {
      return dispatch(requestLogin(email, password));
    }

    return dispatch({
      type: AUTH_FAILURE,
      message: res.message,
      error: true,
    });
  });
};

const fetchCheckActivationToken = (email, code) => ({
  [CALL_API]: {
    types: [
      CHECK_VALIDATION_TOKEN_REQUEST,
      CHECK_VALIDATION_TOKEN_SUCCESS,
      CHECK_VALIDATION_TOKEN_FAILURE,
    ],
    endpoint: `/auth/check_verification_code`,
    method: 'POST',
    body: JSON.stringify({ email, code }),
    mock: false,
  },
});

export const requestCheckActivationToken = (
  email,
  verification_code
) => dispatch => {
  return dispatch(fetchCheckActivationToken(email, verification_code)).then(
    res => {
      if (!res.response.status) {
        return dispatch({
          type: CHECK_VALIDATION_TOKEN_FAILURE,
        });
      }

      return dispatch({
        type: CHECK_VALIDATION_TOKEN_VALID,
      });
    }
  );
};

export const requestLogout = () => dispatch => {
  dispatch({
    type: LOGOUT_REQUEST,
  });

  signOut({ global: true })
    .then(res => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch(() => {
      localStorage.clear();

      dispatch({
        type: LOGOUT_SUCCESS,
      });
    });
};

export const requestChangePassword = data => dispatch => {
  const { oldPassword, newPassword } = data;

  dispatch({
    type: CHANGE_PASSWORD_REQUEST,
  });

  Auth.currentAuthenticatedUser().then(user => {
    Auth.changePassword(user, oldPassword, newPassword)
      .then(() => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
        });
        dispatch(hideModal());
        dispatch(
          addToast({
            status: 'success',
            message: 'Password was successfully updated',
          })
        );
      })
      .catch(err => {
        dispatch({
          type: CHANGE_PASSWORD_FAILURE,
          message: err.message,
        });
      });
  });
};

export const resetChangePassword = () => dispatch => {
  return dispatch({
    type: RESET_CHANGE_PASSWORD,
  });
};

export const requestResetPassword = (email, code, new_password) => dispatch => {
  dispatch({
    type: CHANGE_PASSWORD_REQUEST,
  });

  Auth.forgotPasswordSubmit(email, code, new_password)
    .then(() => {
      return dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
      });
    })
    .catch(err => {
      return dispatch({
        type: CHANGE_PASSWORD_FAILURE,
        message: err.message,
      });
    });
};

export const requestForgotPassword = email => dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_REQUEST,
  });
  Auth.forgotPassword(email)
    .then(() => {
      return dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
      });
    })
    .catch(err => {
      return dispatch({
        type: FORGOT_PASSWORD_FAILURE,
        message: err.message,
      });
    });
};

export const resetAuth = () => dispatch => {
  return dispatch({
    type: RESET_AUTH,
  });
};

const fetchRequestResetPasswords = ids => ({
  [CALL_API]: {
    types: [
      RESET_PASSWORDS_REQUEST,
      RESET_PASSWORDS_SUCCESS,
      RESET_PASSWORDS_FAILURE,
    ],
    endpoint: `/auth/reset_passwords?users_id=${ids}`,
    method: 'POST',
    mock: false,
  },
  ids,
});

export const requestResetPasswords = (usersId, message) => dispatch => {
  return dispatch(fetchRequestResetPasswords(usersId)).then(res => {
    if (validateApiResponse(res)) {
      dispatch(hideModal());
      dispatch(
        addToast({
          status: 'success',
          message: message,
        })
      );
    }
  });
};

const fetchResetPwdReActivate = (email, verification_code, password) => ({
  [CALL_API]: {
    types: [
      RESET_PWD_REACTIVATE_USER_REQUEST,
      RESET_PWD_REACTIVATE_USER_SUCCESS,
      RESET_PWD_REACTIVATE_USER_FAIULURE,
    ],
    endpoint: `/auth/sign_up`,
    method: 'POST',
    body: JSON.stringify({ email, code: verification_code, password }),
    mock: false,
  },
});

export const requestResetPwdReActivate = (
  email,
  verification_code,
  password
) => dispatch => {
  return dispatch(
    fetchResetPwdReActivate(email, verification_code, password)
  ).then(res => {
    if (validateApiResponse(res)) {
      return dispatch(requestLogin(email, password));
    }

    return dispatch({
      type: AUTH_FAILURE,
      message: res.message,
      error: true,
    });
  });
};
