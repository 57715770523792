import { AuthHelperMethods } from './';
const { getIdToken, getAccessToken } = AuthHelperMethods;

export default async function authHeader() {
  // return header with Authorization and IdToken
  const accessToken = await getAccessToken();
  const id = await getIdToken();

  if (accessToken) {
    return { Authorization: accessToken, IdToken: id };
  } else {
    return {};
  }
}
