import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonIcon, Dropdown, Box, Text } from 'hx-react-components';
import { getProductLink } from '../../utils';
import ProductIcon from '../ProductIcon';
import { sendEvent } from '../../actions/EventsActions';

const StyledProductBox = styled.div`
  flex: 0 1 25%;
  margin-bottom: 6px;
  margin-left: 6px;
`;

const StyledProductsContainer = styled.div`
  display: flex;
  margin: 16px 0 0;
  flex-wrap: wrap;
  width: 100%;
  min-width: 410px;
  justify-content: flex-start;
`;

const ProductWrapper = styled.div`
  display: flex;
  position: relative;
  width: 130px;
  height: 130px;
  border: 2px solid #d3d2dc;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: #e8e6f5;
  }
`;

const ProductsDropdownContent = ({ products }) => {
  return (
    <Box pY="medium" pX="medium">
      <StyledProductsContainer>
        {products.map(product => (
          <StyledProductBox key={product.id}>
            <a
              target="_self"
              href={`//${getProductLink(product.slug)}`}
              onClick={() => sendEvent(`Opening ${product.name}`)}
            >
              <ProductWrapper selected={product.slug === 'trends'}>
                <ProductIcon product={product} />
                <Text size="small" weight="bold">
                  {product.name}
                </Text>
              </ProductWrapper>
            </a>
          </StyledProductBox>
        ))}
      </StyledProductsContainer>
    </Box>
  );
};

const ProductsDropdown = ({ products }) => {
  const [isDropdownOpen, SetIsDropdownOpen] = useState(false);
  return (
    <Dropdown
      position="left"
      setLargeMinWidth
      isOpen={isDropdownOpen}
      content={<ProductsDropdownContent products={products} />}
      onClickOutside={() => SetIsDropdownOpen(false)}
    >
      <ButtonIcon
        icon="menu"
        variant="light"
        size="medium"
        onClick={() => {
          const isOpen = !isDropdownOpen;
          sendEvent(
            isOpen ? 'Opening products dropdown' : 'Closing products dropdown'
          );
          SetIsDropdownOpen(isOpen);
        }}
      />
    </Dropdown>
  );
};

ProductsDropdown.propTypes = {
  products: PropTypes.array,
};

ProductsDropdown.defaultProps = {
  products: [],
};

export default ProductsDropdown;
