import { REMOVE_TOAST, ADD_TOAST } from '../../constants/action-types';

const getInitialState = () => {
  return {
    list: [],
  };
};

const initialState = getInitialState();

const toasts = (state = initialState, action) => {
  const types = {
    [ADD_TOAST]: () => {
      const newToast = {
        id: action.id,
        toastProps: action.toastProps,
      };

      const list = state.list;
      list.push(newToast);

      return {
        list: list,
        id: action.id,
        type: action.type,
      };
    },
    [REMOVE_TOAST]: () => {
      const list = state.list.filter(item => item.id !== action.id);
      return {
        list: list,
        type: action.type,
      };
    },
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default toasts;
