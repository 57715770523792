import * as AuthHelperMethods from './AuthHelperMethods';
import CacheHelperMethods from './CacheHelperMethods';
import ApiRequestHeader from './ApiRequestHeader';
import { validateApiResponse } from './ApiResponseValidation';
import { getParams } from './params';
import { generalFetchCall } from './generalFetchCall';

import {
  checkActiveRoute,
  getFormattedDate,
  ammountFormatter,
  isUndefined,
  getInitials,
  getFullName,
  isEmpty,
  objectIsNull,
  newPasswordRules,
  isFormValid,
  validateEmail,
  areLoading,
  getAssignedIds,
  getFilterStatus,
  getProductLink,
} from './misc';

export {
  AuthHelperMethods,
  CacheHelperMethods,
  ApiRequestHeader,
  checkActiveRoute,
  getFormattedDate,
  ammountFormatter,
  validateApiResponse,
  getParams,
  generalFetchCall,
  isUndefined,
  getInitials,
  getFullName,
  isEmpty,
  objectIsNull,
  newPasswordRules,
  isFormValid,
  validateEmail,
  areLoading,
  getAssignedIds,
  getFilterStatus,
  getProductLink,
};
