import {
  Login,
  Registration,
  ForgotPassword,
  ResetPassword,
  GenericError,
  SignOut,
  InvitationLinkExpired,
  AccountSettings,
} from '../routes';

const general = [
  {
    label: 'Login',
    path: '/login',
    private: false,
    component: Login,
    inMenu: false,
    exact: true,
  },
  {
    label: 'Login',
    path: '/logout',
    private: false,
    component: SignOut,
    inMenu: false,
    exact: true,
  },
  {
    label: 'Registration',
    path: '/registration',
    private: false,
    component: Registration,
    inMenu: false,
    exact: true,
  },
  {
    label: 'Forgot password',
    path: '/forgot-password',
    private: false,
    component: ForgotPassword,
    inMenu: false,
    exact: true,
  },
  {
    label: 'Reset password',
    path: '/reset-password',
    private: false,
    component: ResetPassword,
    inMenu: false,
    exact: true,
  },
  {
    label: 'Generic Error',
    path: '/error',
    private: false,
    component: GenericError,
    inMenu: false,
    exact: true,
  },
  {
    label: 'Invitation expired',
    path: '/invitation-expired',
    private: false,
    component: InvitationLinkExpired,
    inMenu: false,
    exact: true,
  },
  {
    label: 'Home',
    path: '/',
    private: true,
    redirectPath: '/login',
    component: AccountSettings,
    inMenu: false,
    exact: true,
    routes: [
      {
        label: 'Account overview',
        path: '/account',
        private: true,
        requiredScopes: ['users-admin'],
        redirectPath: '/login',
        inMenu: true,
        exact: true,
        icon: 'show',
      },
      {
        label: 'Users',
        path: '/users',
        private: true,
        redirectPath: '/login',
        requiredScopes: ['users-admin'],
        inMenu: true,
        exact: false,
        icon: 'users',
      },
      {
        label: 'My products',
        path: '/my-products',
        private: true,
        redirectPath: '/login',
        requiredScopes: ['users-admin'],
        inMenu: true,
        exact: true,
        icon: 'products&services',
      },
    ],
  },
];

export default general;
