import React from 'react';
import { Text } from 'hx-react-components';
import { getFormattedDate } from '../../utils/';

const ProductDates = ({ activationDate, expirationDate }) => {
  return (
    <>
      <div>
        <Text tag="span" size="xbody" variant="dark_light">
          Activation Date: {getFormattedDate(activationDate)}
        </Text>
      </div>
      <div>
        <Text tag="span" size="xbody" variant="dark_light">
          Expiration Date:{' '}
          {expirationDate ? getFormattedDate(expirationDate) : '-'}
        </Text>
      </div>
    </>
  );
};

export default ProductDates;
