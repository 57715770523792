import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ToastList from './components/ToastList';

import { removeToast } from '../../actions/ToastActions';

const StyledToastListContainer = styled.div`
  position: fixed;
  bottom: 32px;
  left: 32px;
  z-index: 2000;
  width: 50%;
  max-width: 460px;
`;

class ToastRootContainer extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.toasts.type === 'ADD_TOAST') {
        this.startCloseTimeout(this.props.toasts.id);
      }
    }
  }
  startCloseTimeout = toastId => {
    const timeout = setTimeout(() => {
      this.props.removeToast(toastId);
    }, 5000);

    this.setState({
      ...this.state,
      timeout: timeout,
    });
  };

  onToastClose = id => {
    if (this.state.timeout) {
      const timeout = clearTimeout(this.state.timeout);
      this.setState({
        ...this.state,
        timeout,
      });
    }

    this.props.removeToast(id);
  };

  render() {
    const { toasts } = this.props;
    return (
      <StyledToastListContainer>
        <ToastList toasts={toasts.list} onToastClose={this.onToastClose} />
      </StyledToastListContainer>
    );
  }
}

const mapDispatchToProps = {
  removeToast,
};

const mapStateToProps = state => ({
  toasts: state.toasts,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastRootContainer);
