import React from 'react';
import styled from 'styled-components';
import { UserInfo, Feedback, Box, Text } from 'hx-react-components';

import { getInitials, getFullName } from '../../utils';

const usersList = selectedUsers => {
  return selectedUsers.map(user => (
    <Box mB="medium" key={user.id}>
      <UserInfo
        size="medium"
        userName={getFullName(user)}
        image={user.avatar}
        initials={getInitials(user)}
        email={user.email}
      />
    </Box>
  ));
};

const ScrollVertical = styled.div`
  max-height: 180px;
  overflow-x: auto;
`;

const UsersRecap = ({ selectedUsers, feedbackText, subTitle }) => {
  const users = usersList(selectedUsers);
  return (
    <>
      {feedbackText && (
        <Box mB="large">
          <Feedback status="warning" message={feedbackText} />
        </Box>
      )}
      <Box mB="large">
        {subTitle && <Text style={{ whiteSpace: 'pre-line' }}>{subTitle}</Text>}
      </Box>
      <ScrollVertical>{users}</ScrollVertical>
    </>
  );
};

export default UsersRecap;
