import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ToastItem from './ToastItem';

const transitionName = 'fadeInDownUp';

const ToastList = ({ toasts, onToastClose }) => {
  return (
    <React.Fragment>
      <ReactCSSTransitionGroup
        transitionName={transitionName}
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {toasts.map(item => (
          <ToastItem
            key={item.id}
            toastId={item.id}
            closeToast={onToastClose}
            {...item.toastProps}
          />
        ))}
      </ReactCSSTransitionGroup>
    </React.Fragment>
  );
};

export default ToastList;
