import React from 'react';
import { NotLoggedLayout, AuthLayout } from '../../layouts';
import { LoginContainer } from '../../containers';

const Login = props => (
  <NotLoggedLayout>
    <AuthLayout>
      <LoginContainer {...props} />
    </AuthLayout>
  </NotLoggedLayout>
);

export default Login;
