import { Box, Checkbox, Col, Row, Text } from 'hx-react-components';
import React from 'react';

const ProductFusionData = ({
  productId,
  productSlug,
  fusions,
  onFusionChange,
}) => {
  return (
    <Box pB="medium">
      <Row valign="middle">
        <Col span="auto">
          <Box pT="small" pB="small">
            <Text as="span" weight="bold" size="xbody">
              Fusion Data
            </Text>
          </Box>
        </Col>
      </Row>
      <Row halign="left" valign="middle">
        {fusions.map((fusion, index) => (
          <Col key={`fusion-data-${productId}-${index}`} span="auto">
            <Row valign="middle">
              <Col span="auto">
                <Checkbox
                  checked={fusion.assigned}
                  onChange={e => {
                    onFusionChange(e.target.checked, productId, fusion.id);
                  }}
                  disabled={
                    productSlug === 'affinity-us-gwi' && fusion.slug === 'gwi'
                  }
                >
                  <Text>{fusion.name}</Text>
                </Checkbox>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Box>
  );
};

export default ProductFusionData;
