import {
  GET_USER_PRODUCTS_REQUEST,
  GET_USER_PRODUCTS_SUCCESS,
  GET_USER_PRODUCTS_FAILURE,
  ADD_USER_SUCCESS,
  RESTORE_PROUCTS_LICENSES,
  REACTIVATE_DISABLE_USER_SUCCESS,
} from '../../constants/action-types';

/** updateProductLicens
 * This func updates user product licences whenever a user is Added (-1 licence), Disabled (+1 licence), Disbale bulk (+n_users licence)
 * userProducts: list of current state userProducts
 * productsAssignedIds: array of products ids -> es: [1, 1, 1] or [1, 2, 1] etc etc
 * restore
 */
const updateProductLicences = (userProducts, productsAssignedIds, restore) => {
  return userProducts.map(product => {
    if (!productsAssignedIds.includes(product.id)) {
      return product;
    }

    /**
     * Getting the quantity for each product ids
     * Es: [1, 1, 2, 3, 3] -> for products 1 quantity is 2, for products 2 quantity is 1, for products 3 quantity is 2,
     */
    const productQuantity = productsAssignedIds.filter(
      productId => productId === product.id
    ).length;

    return {
      ...product,
      licenses: {
        ...product.licenses,
        available: !restore
          ? product.licenses.available - productQuantity
          : product.licenses.available + productQuantity,
      },
    };
  });
};

const getInitialState = () => {
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

const initialState = getInitialState();

const userProducts = (state = initialState, action) => {
  const types = {
    [GET_USER_PRODUCTS_REQUEST]: () => ({
      ...state,
      isLoading: true,
    }),
    [GET_USER_PRODUCTS_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      error: false,
      data: action.response,
    }),
    [GET_USER_PRODUCTS_FAILURE]: () => ({
      ...state,
      isLoading: false,
      error: true,
      message: action.message,
    }),
    [ADD_USER_SUCCESS]: () => ({
      ...state,
      data: updateProductLicences(state.data, action.productsIds),
    }),
    [REACTIVATE_DISABLE_USER_SUCCESS]: () => ({
      ...state,
      data: updateProductLicences(state.data, action.productsIds),
    }),
    [RESTORE_PROUCTS_LICENSES]: () => ({
      ...state,
      data: updateProductLicences(state.data, action.productsIds, true),
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default userProducts;
