const getAssignedIds = items => {
  return items
    ? items
        .filter(item => item.assigned)
        .map(item => {
          return item.id;
        })
    : [];
};

export const selectAssignedProductsIds = state => {
  if (!state.user.data.products) {
    return [];
  }

  return getAssignedIds(state.user.data.products);
};

export const selectAssignedAddonsIds = state => {
  if (!state.user.data.products) {
    return [];
  }

  let addonsIds = [];
  state.user.data.products
    .filter(product => product.assigned)
    .forEach(product => {
      addonsIds = addonsIds.concat(getAssignedIds(product.addons));
    });
  return addonsIds;
};

export const selectAssignedScopesIds = state => {
  if (!state.user.data.scopes) {
    return [];
  }

  let scopesIds = [];
  state.user.data.scopes
    .filter(scope => scope.assigned)
    .forEach(scope => {
      scopesIds = scopesIds.concat(scope.id);
    });
  return scopesIds;
};

export const selectAssignedFusionsIds = state => {
  if (!state.user.data.products) {
    return [];
  }

  let fusionsIds = [];
  state.user.data.products
    .filter(product => product.assigned)
    .forEach(product => {
      fusionsIds = fusionsIds.concat(getAssignedIds(product.fusions));
    });
  return fusionsIds;
};
