import { CALL_API } from '../middleware/api';

import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DISABLE_USERS_REQUEST,
  DISABLE_USERS_SUCCESS,
  DISABLE_USERS_FAILURE,
  REACTIVATE_DISABLE_USER_REQUEST,
  REACTIVATE_DISABLE_USER_SUCCESS,
  REACTIVATE_DISABLE_USER_FAILURE,
  RESET_USERS_FILTERS,
  GET_ME_REQUEST,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
  RESEND_USER_INVITE_REQUEST,
  RESEND_USER_INVITE_SUCCESS,
  RESEND_USER_INVITE_FAILURE,
  TOGGLE_SELECT_USER,
  TOGGLE_SELECT_ALL_USERS,
  GET_NEW_USER_REQUEST,
  GET_NEW_USER_SUCCESS,
  GET_NEW_USER_FAILURE,
  TOGGLE_USER_PRODUCT_ASSIGNMENT,
  TOGGLE_USER_SCOPE_ASSIGNMENT,
  UPDTAE_AVATAR_REQUEST,
  UPDTAE_AVATAR_SUCCESS,
  UPDTAE_AVATAR_FAILURE,
  RESTORE_PROUCTS_LICENSES,
  UPDTAE_USER_FULLNAME_REQUEST,
  UPDTAE_USER_FULLNAME_SUCCESS,
  UPDTAE_USER_FULLNAME_FAILURE,
  TOGGLE_CHANGE_ME_FULLNAME_POPOVER,
} from '../constants/action-types';
import { hideModal } from './ModalActions';
import { addToast } from './ToastActions';

import { validateApiResponse, getParams, generalFetchCall } from '../utils';
import { getCompanyId } from '../utils/CognitoHelperMethods';

import { requestMeProducts } from './ProductsActions';
import { requestScopes } from './ScopesActions';

export const requestUsers = paramsRequest => (dispatch, getState) => {
  const { params } = getState().users;
  const { decodedToken } = getState().auth;
  const nextParams = getParams(params, paramsRequest);

  const companyId = getCompanyId(decodedToken);

  dispatch(
    generalFetchCall({
      params: nextParams,
      method: 'GET',
      paramsUrl: `/companies/${companyId}/users`,
      request: GET_USERS_REQUEST,
      success: GET_USERS_SUCCESS,
      failure: GET_USERS_FAILURE,
      mock: false,
    })
  );
};

const fetchAddUser = (user, companyId) => {
  return {
    [CALL_API]: {
      types: [ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE],
      endpoint: `/companies/${companyId}/users`,
      method: 'POST',
      body: JSON.stringify(user),
      mock: false,
    },
    productsIds: user.products,
  };
};

export const addUser = user => (dispatch, getState) => {
  const { decodedToken } = getState().auth;
  const companyId = getCompanyId(decodedToken);
  return dispatch(fetchAddUser(user, companyId)).then(res => {
    if (validateApiResponse(res)) {
      dispatch(hideModal());

      const { q, pagination, sort } = getState().users.params;
      dispatch(requestUsers({ q, pagination, sort }));

      dispatch(
        addToast({
          status: 'success',
          message: 'User was successfully added',
          buttonText: 'Show users',
        })
      );
    }
  });
};

const fetchMe = () => {
  return {
    [CALL_API]: {
      types: [GET_ME_REQUEST, GET_ME_SUCCESS, GET_ME_FAILURE],
      endpoint: `/users/me`,
      method: 'GET',
      mock: false,
    },
  };
};

export const requestMe = () => dispatch => {
  return dispatch(fetchMe());
};

const updateAvatar = avatar => {
  return {
    [CALL_API]: {
      types: [
        UPDTAE_AVATAR_REQUEST,
        UPDTAE_AVATAR_SUCCESS,
        UPDTAE_AVATAR_FAILURE,
      ],
      endpoint: `/users/me`,
      method: 'PUT',
      body: JSON.stringify({ avatar }),
      mock: false,
    },
    avatar,
  };
};

export const requestUpdateAvatar = avatar => dispatch => {
  return dispatch(updateAvatar(avatar)).then(res => {
    if (validateApiResponse(res)) {
      dispatch(hideModal());
      dispatch(
        addToast({
          status: 'success',
          message: 'Your avatar has been updated',
        })
      );
    }
  });
};

const updateUserFullname = (name, surname) => {
  return {
    [CALL_API]: {
      types: [
        UPDTAE_USER_FULLNAME_REQUEST,
        UPDTAE_USER_FULLNAME_SUCCESS,
        UPDTAE_USER_FULLNAME_FAILURE,
      ],
      endpoint: `/users/me`,
      method: 'PUT',
      body: JSON.stringify({ name, surname }),
      mock: false,
    },
    name,
    surname,
  };
};

export const requestUpdateUserFullname = (name, surname) => dispatch => {
  return dispatch(updateUserFullname(name, surname)).then(res => {
    if (validateApiResponse(res)) {
      dispatch(toggleChangeMeFullnamePopover(false));
      dispatch(
        addToast({
          status: 'success',
          message: 'Name successfully changed',
        })
      );
    }
  });
};

export const toggleChangeMeFullnamePopover = open => dispatch => {
  dispatch({
    type: TOGGLE_CHANGE_ME_FULLNAME_POPOVER,
    open: open,
  });
};

const fetchUser = id => {
  return {
    [CALL_API]: {
      types: ['', '', ''],
      endpoint: `/users/${id}`,
      method: 'GET',
      mock: false,
    },
  };
};

export const requestUser = id => (dispatch, getState) => {
  const { auth } = getState();

  dispatch({
    type: GET_USER_REQUEST,
  });

  return Promise.all([
    dispatch(requestMeProducts()),
    dispatch(fetchUser(id)),
    dispatch(requestScopes()),
  ])
    .then(res => {
      const data = [...res, auth.decodedToken.scopes];
      dispatch({
        type: GET_USER_SUCCESS,
        response: data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_USER_FAILURE,
      });
    });
};

const fetchEditUser = (data, id) => {
  return {
    [CALL_API]: {
      types: [EDIT_USER_REQUEST, EDIT_USER_SUCCESS, EDIT_USER_FAILURE],
      endpoint: `/users/${id}`,
      method: 'PUT',
      body: JSON.stringify(data),
      mock: false,
    },
  };
};

export const requestEditUser = (data, id) => dispatch => {
  return dispatch(fetchEditUser(data, id)).then(res => {
    if (validateApiResponse(res)) {
      dispatch(hideModal());
      dispatch(
        addToast({
          status: 'success',
          message: 'User was successfully edited',
        })
      );
    }
  });
};

const fetchDeleteUser = id => {
  return {
    [CALL_API]: {
      types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
      endpoint: `/users/${id}`,
      method: 'DELETE',
      mock: false,
    },
    id,
  };
};

export const requestDeleteUser = (id, message) => dispatch => {
  return dispatch(fetchDeleteUser(id)).then(res => {
    if (validateApiResponse(res)) {
      dispatch(hideModal());
      dispatch(
        addToast({
          status: 'success',
          message: message,
        })
      );
    }
  });
};

export const resetAddUser = () => dispatch => {
  return dispatch({
    type: 'RESET_ADD_USER',
  });
};

export const resetUsersParams = () => dispatch => {
  return dispatch({
    type: 'RESET_USERS_PARAMS',
  });
};

const fetchDisableUsers = ids => {
  return {
    [CALL_API]: {
      types: [
        DISABLE_USERS_REQUEST,
        DISABLE_USERS_SUCCESS,
        DISABLE_USERS_FAILURE,
      ],
      endpoint: `/users/deactivate?users_id=${ids}`,
      method: 'PUT',
      mock: false,
    },
    ids,
  };
};

export const requestDisableUsers = (ids, productsIds, message) => dispatch => {
  return dispatch(fetchDisableUsers(ids)).then(res => {
    if (validateApiResponse(res)) {
      dispatch({
        type: RESTORE_PROUCTS_LICENSES,
        productsIds,
      });
      dispatch(hideModal());
      dispatch(
        addToast({
          status: 'success',
          message: message,
        })
      );
    }
  });
};

const fetchReactivateUser = (id, data) => {
  return {
    [CALL_API]: {
      types: [
        REACTIVATE_DISABLE_USER_REQUEST,
        REACTIVATE_DISABLE_USER_SUCCESS,
        REACTIVATE_DISABLE_USER_FAILURE,
      ],
      endpoint: `/users/${id}/reactivate`,
      method: 'PUT',
      body: JSON.stringify(data),
      mock: false,
    },
    id,
    productsIds: data.products,
  };
};

export const requestReactivateUser = (id, data, message) => dispatch => {
  return dispatch(fetchReactivateUser(id, data)).then(res => {
    if (validateApiResponse(res)) {
      dispatch(hideModal());
      dispatch(
        addToast({
          status: 'success',
          message: message,
        })
      );
    }
  });
};

export const resetUsersFilters = () => {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_USERS_FILTERS,
    });
    const { params } = getState().users;
    const { decodedToken } = getState().auth;
    const companyId = getCompanyId(decodedToken);
    dispatch(
      generalFetchCall({
        params: params,
        paramsUrl: `/companies/${companyId}/users`,
        request: GET_USERS_REQUEST,
        success: GET_USERS_SUCCESS,
        failure: GET_USERS_FAILURE,
        mock: false,
      })
    );
  };
};

export const toggleUserSelection = id => dispatch => {
  return dispatch({
    type: TOGGLE_SELECT_USER,
    id,
  });
};

export const toggleAllUserSelection = toggle => dispatch => {
  return dispatch({
    type: TOGGLE_SELECT_ALL_USERS,
    toggle,
  });
};

const resendUserInvite = userId => {
  return {
    [CALL_API]: {
      types: [
        RESEND_USER_INVITE_REQUEST,
        RESEND_USER_INVITE_SUCCESS,
        RESEND_USER_INVITE_FAILURE,
      ],
      endpoint: `/users/${userId}/send_invitation_remainder`,
      method: 'POST',
      mock: false,
    },
    userId,
  };
};

export const requestResendUserInvite = (userId, message) => dispatch => {
  return dispatch(resendUserInvite(userId)).then(res => {
    if (validateApiResponse(res)) {
      dispatch(hideModal());
      dispatch(
        addToast({
          status: 'success',
          message: message,
        })
      );
    }
  });
};

export const toggleUserProductAssignment = (
  assigned,
  productId,
  addonId,
  fusionId
) => dispatch => {
  dispatch({
    type: TOGGLE_USER_PRODUCT_ASSIGNMENT,
    data: {
      assigned,
      productId,
      addonId,
      fusionId,
    },
  });
};

export const toggleUserScopeAssignment = (assigned, scopeId) => dispatch => {
  dispatch({
    type: TOGGLE_USER_SCOPE_ASSIGNMENT,
    data: {
      assigned,
      scopeId,
    },
  });
};

export const requestNewUser = () => (dispatch, getState) => {
  const { auth, userProducts } = getState();

  dispatch({
    type: GET_NEW_USER_REQUEST,
  });

  return dispatch(requestScopes()).then(res => {
    if (validateApiResponse(res)) {
      const data = [userProducts, res, auth.decodedToken.scopes];
      return dispatch({
        type: GET_NEW_USER_SUCCESS,
        response: data,
      });
    }

    return dispatch({
      type: GET_NEW_USER_FAILURE,
    });
  });
};
