const MOCK_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001'
    : 'https://helixa-account-mock-api.herokuapp.com';

const BASE_URL = {
  develop: 'https://api-develop-accounts.helixa.ai',
  staging: 'https://api-staging-accounts.helixa.ai',
  production: 'https://api-accounts.helixa.ai',
};

const IS_LOCAL = process.env.REACT_APP_IS_LOCAL === 'true';

export const API = {
  BASE_URL: BASE_URL[process.env.REACT_APP_API_ENV],
  MOCK_BASE_URL,
};

export const SSO_URLS = {
  localhost: {
    REACT_APP_ID_SERVER_BASE: `//localhost-home.helixa.ai:3003`,
    REACT_APP_ID_SERVER_AUTH: `//localhost-home.helixa.ai:3003/login`,
  },
  develop: {
    REACT_APP_ID_SERVER_BASE: `//develop-home.helixa.ai`,
    REACT_APP_ID_SERVER_AUTH: `//develop-home.helixa.ai/login`,
  },
  staging: {
    REACT_APP_ID_SERVER_BASE: '//staging-home.helixa.ai',
    REACT_APP_ID_SERVER_AUTH: '//staging-home.helixa.ai/login',
  },
  production: {
    REACT_APP_ID_SERVER_BASE: '//home.helixa.ai',
    REACT_APP_ID_SERVER_AUTH: '//home.helixa.ai/login',
  },
};

export const PRODUCT_LINK = {
  develop: {
    'helixa-csm': `${IS_LOCAL ? 'localhost-' : 'develop-'}csm.helixa.ai${
      IS_LOCAL ? ':3004' : ''
    }`,
    'hx-engine-ui': `${
      IS_LOCAL ? 'localhost-' : 'develop-'
    }engine-ui.helixa.ai${IS_LOCAL ? ':3005' : ''}`,
    'affinity-global': `${
      IS_LOCAL ? 'localhost-' : 'develop-'
    }discovery-ww.helixa.ai${IS_LOCAL ? ':3006' : ''}`,
    'affinity-us': `${
      IS_LOCAL ? 'localhost-' : 'develop-'
    }discovery-us.helixa.ai${IS_LOCAL ? ':3006' : ''}`,
    'affinity-us-gwi': `${
      IS_LOCAL ? 'localhost-' : 'develop-'
    }discovery-us-gwi.helixa.ai${IS_LOCAL ? ':3006' : ''}`,
    'affinity-gb': `${
      IS_LOCAL ? 'localhost-' : 'develop-'
    }discovery-gb.helixa.ai${IS_LOCAL ? ':3006' : ''}`,
    listen: `${IS_LOCAL ? 'localhost-' : 'develop-'}listen.helixa.ai${
      IS_LOCAL ? ':3013' : ''
    }`,
    trends: `${IS_LOCAL ? 'localhost-' : 'develop-'}trends.helixa.ai${
      IS_LOCAL ? ':3012' : ''
    }`,
  },
  staging: {
    'affinity-global': 'staging-discovery-ww.helixa.ai',
    'affinity-us': 'staging-discovery-us.helixa.ai',
    'affinity-us-gwi': 'staging-discovery-us-gwi.helixa.ai',
    'affinity-gb': 'staging-discovery-gb.helixa.ai',
    'helixa-csm': 'staging-csm.helixa.ai',
    'hx-engine-ui': 'staging-engine-ui.helixa.ai',
    listen: 'staging-listen.helixa.ai',
    trends: 'staging-trends.helixa.ai',
  },
  production: {
    'affinity-global': 'discovery-ww.helixa.ai',
    'affinity-us': 'discovery-us.helixa.ai',
    'affinity-us-gwi': 'discovery-us-gwi.helixa.ai',
    'affinity-gb': 'discovery-gb.helixa.ai',
    'helixa-csm': 'csm.helixa.ai',
    'hx-engine-ui': 'engine-ui.helixa.ai',
    listen: 'listen.helixa.ai',
    trends: 'trends.helixa.ai',
  },
};

export const COLORS = {
  products: {
    'affinity-global': 'rgb(7, 131, 117)',
    'affinity-us': 'rgb(91,83,223)',
    'hx-engine-ui': 'rgb(23, 121, 204)',
    'helixa-csm': 'rgb(23, 121, 204)',
    'affinity-us-gwi': 'rgb(7, 131, 117)',
    'affinity-gb': 'rgb(225, 40, 87)',
    listen: 'rgb(23, 121, 204)',
    trends: 'rgb(255,153,0)',
  },
};

export const ICONS = {
  products: {
    'affinity-global': 'country',
    'affinity-us': 'usa',
    'helixa-csm': 'support',
    'affinity-us-gwi': 'usa',
    'affinity-gb': 'uk',
    listen: 'campaign',
    trends: 'trending',
  },
};

export const LEGAL_URL =
  'https://www.helixa.ai/hubfs/Helixa%202021/2021Q2_Assets/MSA%202021/Helixa%20MASTER%20SUBSCRIPTION%20AGREEMENT%20(1).pdf?utm_campaign=MSA%20Agreement&utm_source=MSA%20Agreement&utm_medium=MSA%20Agreement&utm_content=MSA%20Agreement';

export const PRIVACY_URL = 'https://www.helixa.ai/privacy-policy';

export const FRESHDESK_URL = '//resources.helixa.ai';
