import React, { memo } from 'react';
import {
  Panel,
  Row,
  Col,
  UserInfo,
  ListGroup,
  Button,
  Text,
  Loading,
  Dropdown,
} from 'hx-react-components';

import { getInitials, getFullName, getFormattedDate } from '../../utils';
import { LanguageConsumer } from '../../contexts';
import translations from '../../lang';
import ChangeNamePopover from './ChangeNamePopover';

const MyProfilePanelFooter = ({
  expireString,
  onOpenChangePwdModal,
  onChangeUserFullname,
  onOpenChangeFullnamePopover,
  me,
}) => {
  const { user, isLoading, popoverLoading, changeNamePopoverOpen } = me;
  return (
    <LanguageConsumer>
      {lang => (
        <Row direction="column">
          <Col>
            <Text
              variant="dark_lighter"
              size="small"
              style={{ marginBottom: '10px' }}
            >
              Your password will expire on {isLoading ? '-' : expireString}
            </Text>
            <ListGroup
              size="small"
              direction="row"
              halign="left"
              items={[
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  size="small"
                  onClick={onOpenChangePwdModal}
                >
                  {translations[lang].COMMON['CHANGE_PASSWORD']}
                </Button>,
                <Dropdown
                  setLargeMinWidth
                  isOpen={changeNamePopoverOpen}
                  content={
                    <ChangeNamePopover
                      user={user}
                      onChangeNameSubmit={onChangeUserFullname}
                      onPopoverClose={() => onOpenChangeFullnamePopover(false)}
                      isLoading={popoverLoading}
                    />
                  }
                  position="right"
                >
                  <Button
                    disabled={isLoading}
                    variant={!changeNamePopoverOpen ? 'outlined' : 'standard'}
                    size="small"
                    onClick={() => onOpenChangeFullnamePopover(true)}
                  >
                    CHANGE NAME
                  </Button>
                </Dropdown>,
              ]}
            />
          </Col>
        </Row>
      )}
    </LanguageConsumer>
  );
};

const MyProfilePanel = ({
  me,
  onOpenChangePwdModal,
  onOpenChangeAvatarModal,
  onChangeUserFullname,
  onOpenChangeFullnamePopover,
}) => {
  const { user, isLoading } = me;
  const expire_string = getFormattedDate(me.user.expired_password);

  return (
    <LanguageConsumer>
      {lang => (
        <Panel
          title={translations[lang].PAGE_ACCOUNT_SETTINGS['MY_PROFILE']}
          footer={
            <MyProfilePanelFooter
              me={me}
              expireString={expire_string}
              onOpenChangePwdModal={onOpenChangePwdModal}
              onChangeUserFullname={onChangeUserFullname}
              onOpenChangeFullnamePopover={onOpenChangeFullnamePopover}
            />
          }
        >
          <Loading isLoading={isLoading}>
            {user.name && (
              <UserInfo
                userName={getFullName(user)}
                initials={getInitials(user)}
                image={user.avatar}
                email={user.email}
                onAvatarClick={onOpenChangeAvatarModal}
              />
            )}
          </Loading>
        </Panel>
      )}
    </LanguageConsumer>
  );
};

export default memo(MyProfilePanel);
