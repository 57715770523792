import React, { useState } from 'react';
import { Row, Col, Button, Box, Search } from 'hx-react-components';
import Filter from '../Filter';

const FiltersPanel = ({
  onSearchChange,
  searchPlaceholder,
  filters,
  onFilterChange,
  activeFilters,
  onClearFilters,
}) => {
  const [isFilterOpen, SetIsFilterOpen] = useState(false);
  const toggleFiltersButton = (
    <Button
      size="small"
      variant="outlined"
      icon="filters"
      data-test-selector="open-filters"
      onClick={() => SetIsFilterOpen(!isFilterOpen)}
    >
      {!isFilterOpen ? 'filters' : 'close filters'}
    </Button>
  );

  const clearFiltersbutton = (
    <Button size="small" variant="subtle" onClick={onClearFilters}>
      Clear All
    </Button>
  );

  return (
    <React.Fragment>
      <Row halign="between" valign="middle">
        <Col span={'auto'}>
          <Row valign="middle">
            <Col span="auto">{toggleFiltersButton}</Col>
            {activeFilters && <Col span="auto">{clearFiltersbutton}</Col>}
          </Row>
        </Col>
        <Col span={4}>
          <Search
            placeholder={searchPlaceholder}
            delay={500}
            onSearchChange={onSearchChange}
          />
        </Col>
      </Row>
      {isFilterOpen && (
        <Box mT="large">
          <Row>
            {filters.map((filter, index) => (
              <Col key={index} span={3}>
                <div>
                  <Filter
                    onFilterChange={onFilterChange}
                    field={filter.field}
                    filterType={filter.filterType}
                    range={filter.range}
                    label={filter.label}
                    options={filter.options}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Box>
      )}
    </React.Fragment>
  );
};

export default FiltersPanel;
