import {
  GET_COMPANY_DETAIL_REQUEST,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAILURE,
} from '../../constants/action-types';

const getInitialState = () => {
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const initialState = getInitialState();

const company = (state = initialState, action) => {
  const types = {
    [GET_COMPANY_DETAIL_REQUEST]: () => ({
      ...state,
      isLoading: true,
      error: false,
    }),
    [GET_COMPANY_DETAIL_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      data: action.response,
    }),
    [GET_COMPANY_DETAIL_FAILURE]: () => ({
      ...state,
      isLoading: false,
      message: action.message,
      error: true,
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default company;
