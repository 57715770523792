import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoggedLayout } from '../layouts';

const PrivateRoute = ({
  component: Component,
  auth,
  redirectPath,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return auth.isLogged ? (
        <LoggedLayout>
          <Component {...props} {...rest} decodedToken={auth.decodedToken} />
        </LoggedLayout>
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(PrivateRoute);
