import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { RootContainer } from './containers/';
import configureStore from './store/configureStore';

import Amplify from 'aws-amplify';

import { COGNITO } from './constants/';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: COGNITO.Auth.REGION,
    userPoolId: COGNITO.Auth.USER_POOL_ID,
    userPoolWebClientId: COGNITO.Auth.APP_CLIENT_ID,
  },
});

const store = configureStore();

ReactDOM.render(
  <RootContainer store={store} />,
  document.getElementById('root')
);

serviceWorker.unregister();
