import React from 'react';
import { NotLoggedLayout, AuthLayout } from '../layouts';

import { Text, Row, Col, Box, Anchor } from 'hx-react-components';

const NotFound = () => (
  <NotLoggedLayout>
    <AuthLayout>
      <Box mB="large" mT="xlarge">
        <Row halign="center">
          <Col span={'auto'}>
            <Text
              align="center"
              tag="h1"
              variant="dark_lighter"
              size="headline"
            >
              THIS IS NOT THE PAGE
              <br />
              YOU WERE LOOKING FOR, SORRY.
            </Text>
          </Col>
        </Row>
      </Box>
      <Box mB="large" mT="xlarge">
        <Row halign="center">
          <Col span={'auto'}>
            <Anchor href={'/'}>Go to home page</Anchor>
          </Col>
        </Row>
      </Box>
    </AuthLayout>
  </NotLoggedLayout>
);

export default NotFound;
