import { SERVER_DOWN, RESET_GENERIC_ERROR } from '../../constants/action-types';

const getInitialState = () => {
  return {
    redirect: false,
  };
};

const initialState = getInitialState();

const genericError = (state = initialState, action) => {
  const types = {
    [SERVER_DOWN]: () => ({
      ...state,
      redirect: true,
    }),
    [RESET_GENERIC_ERROR]: () => {
      return initialState;
    },
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default genericError;
