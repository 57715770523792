import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  SelectDates,
  MultipleSelection,
  FormItem,
} from 'hx-react-components';

class Filter extends Component {
  formatFilterData = (value, range) => {
    if (range) {
      return {
        from: value[0],
        to: value[1],
      };
    }

    return value.toString();
  };

  handleFilterChange = value => {
    const { onFilterChange, field, range } = this.props;
    const filter_value = this.formatFilterData(value, range);
    onFilterChange({ field, value: filter_value });
  };

  getStandard = () => {
    const { options, label } = this.props;
    return (
      <FormItem label={label}>
        <div data-test-selector="select-container">
          <Select
            onItemChange={this.handleFilterChange}
            options={options}
            showAllOption={true}
          />
        </div>
      </FormItem>
    );
  };

  getMultipleSelection = () => {
    const { options, label } = this.props;
    return (
      <FormItem label={label}>
        <MultipleSelection
          onSaveItems={this.handleFilterChange}
          options={options}
        />
      </FormItem>
    );
  };

  getSelectDates = () => {
    const { options, label, range } = this.props;
    const selectedStartDate = options[0].selectedStartDate;
    const selectedEndDate = range ? options[1].selectedEndDate : null;
    return (
      <FormItem label={label}>
        <div data-test-selector="select-date-container">
          <SelectDates
            onSaveDates={this.handleFilterChange}
            setLargeMinWidth
            dropdownPosition="left"
            blockDates="future"
            options={options}
            calendarChoice={range ? 'range' : 'single'}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
          />
        </div>
      </FormItem>
    );
  };

  getFilterType = type => {
    const filterTypesMap = {
      standard: () => this.getStandard(),
      multiple: () => this.getMultipleSelection(),
      date: () => this.getSelectDates(),
    };
    return filterTypesMap[type]();
  };

  render() {
    const { filterType } = this.props;
    const filter = this.getFilterType(filterType);

    return (
      <React.Fragment>
        <div className="filter-container">{filter}</div>
      </React.Fragment>
    );
  }
}

Filter.propTypes = {
  field: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filterType: PropTypes.oneOf(['standard', 'multiple', 'date']),
  range: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      selectedStartDate: PropTypes.number,
      selectedEndDate: PropTypes.number,
    }),
  ]),
};

export default Filter;
