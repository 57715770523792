import React from 'react';
import { Row, Col, Panel, Box, Icon, Text } from 'hx-react-components';
import { getProductLink } from '../../utils';
import styled from 'styled-components';
import { sendEvent } from '../../actions/EventsActions';

const ProductImage = styled.div`
  height: 200px;
  width: 100%;
  background-image: ${props =>
    `url('https://cdn.helixa.ai/images/home/${props.product}.jpg');`}
  background-position: center;
  background-size: cover;
`;

const ProductPanelContainer = styled.div`
  height: 100%;
  padding-bottom: 16px;
  & > div {
    background-color: white;
    padding: 0px;
    height: 100%;
    & > div {
      padding: 0px;
    }
  }
`;
const ProductPanel = ({ products, auth }) => {
  return (
    <React.Fragment>
      <Row valign="equal">
        {products.map(item => (
          <Col span={3} key={item.id}>
            <ProductPanelContainer>
              <Panel>
                <a
                  target="_self"
                  href={`//${getProductLink(item.slug)}`}
                  onClick={() => sendEvent(`Opening ${item.name}`)}
                >
                  <ProductImage product={item.slug} />
                  <Box pX="xlarge" pY="xlarge">
                    <Row>
                      <Col span="auto">
                        <Text tag="h3" size="body" weight="bold">
                          {item.slug !== 'trends'
                            ? item.name
                            : `${item.name} (Beta)`}
                        </Text>
                      </Col>
                      <Col span="auto">
                        <Icon
                          size="medium"
                          icon="arrow-right"
                          variant="primary"
                        />
                      </Col>
                    </Row>
                  </Box>
                  <Box pL="xlarge" pR="xlarge" pB="xlarge">
                    <Row>
                      <Col>
                        <Text size="xbody" variant="dark_lighter">
                          {item.description}
                        </Text>
                      </Col>
                    </Row>
                  </Box>
                </a>
              </Panel>
            </ProductPanelContainer>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default ProductPanel;
