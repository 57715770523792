import React, { Component } from 'react';
import { clearLocalSession } from '../utils/CognitoHelperMethods';

function withClearSession(WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      clearLocalSession();
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withClearSession;
