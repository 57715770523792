import React, { useState } from 'react';
import { ButtonIcon } from 'hx-react-components';
import PropTypes from 'prop-types';
import InputFormItem from '../InputFormItem';

const PasswordFormItem = ({
  refs,
  onPasswordChange,
  size,
  placeholder,
  name,
  value,
  error,
  errorMessage,
  label,
  formItemVariant,
  autoComplete,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputFormItem
      error={error}
      ref={refs}
      label={label}
      message={errorMessage}
      value={value}
      name={name}
      type={showPassword ? 'text' : 'password'}
      size={size}
      onChange={onPasswordChange}
      autoComplete={autoComplete}
      withIcon={
        <ButtonIcon
          icon={showPassword ? 'hide' : 'show'}
          size="small"
          variant={formItemVariant}
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        />
      }
      {...rest}
    />
  );
};

PasswordFormItem.propTypes = {
  tabIndex: PropTypes.number,
  onPasswordChange: PropTypes.func,
  formItemVariant: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  autoComplete: PropTypes.string,
};

PasswordFormItem.defaultProps = {
  formItemVariant: 'light',
};

export default PasswordFormItem;
