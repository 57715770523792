import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { requestLogout } from '../../actions/AuthActions';
import { requestUserProducts } from '../../actions/UserProductsActions';
import { selectFilteredUserProducts } from '../../selectors/';
import { Container, Box, Loading } from 'hx-react-components';

import { ProductPanel } from '../../components';

class HomeContainer extends Component {
  componentDidMount() {
    this.props.requestUserProducts();
  }

  render() {
    const { userProducts, auth } = this.props;
    const { isLoading, data } = userProducts || true;

    return (
      <Container>
        <Box pB="xlarge" pT="xlarge">
          <Box pB="medium" pT="medium">
            <Loading isLoading={isLoading}>
              <ProductPanel products={data} auth={auth} />
            </Loading>
          </Box>
        </Box>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  requestLogout,
  requestUserProducts,
};

const mapStateToProps = state => ({
  auth: state.auth,
  userProducts: selectFilteredUserProducts(state),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomeContainer)
);
