import { API } from '../constants/config';
import { ApiRequestHeader } from '../utils/';

const getPageCount = response => {
  const pageCount = response.headers.get('x-total-count');
  if (pageCount) {
    return parseInt(pageCount);
  }

  return false;
};

const getEndpointBaseUrl = mock => (mock ? API.MOCK_BASE_URL : API.BASE_URL);

const getActionData = (pageCount, data, response, successType) => {
  const actionData = {
    response: data,
    status: response.status,
    error: false,
    type: successType,
  };

  if (!pageCount) {
    return actionData;
  }

  return {
    ...actionData,
    pageCount,
  };
};

const callApi = async ({ endpoint, method, body, mock = false }) => {
  const fullUrl =
    endpoint.indexOf(API.BASE_URL) === -1
      ? getEndpointBaseUrl(mock) + endpoint
      : endpoint;

  const authRequestHeader = await ApiRequestHeader();

  const headerAuth = endpoint === '/auth' ? {} : authRequestHeader;

  return fetch(fullUrl, {
    headers: {
      ...headerAuth,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method,
    body,
  }).then(response => {
    if (response.status === 204) {
      return { response };
    }

    return response.json().then(data => {
      if (!response.ok) {
        return Promise.reject({ data, response });
      }

      return { data, response };
    });
  });
};

export const CALL_API = 'Call API';

export default store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint } = callAPI;
  const { types, method, body, mock } = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;

  next(actionWith({ type: requestType }));

  return callApi({ endpoint, method, body, mock }).then(
    successResponse => {
      const { data, response } = successResponse;
      const pageCount = getPageCount(response);

      const actionData = getActionData(pageCount, data, response, successType);

      return next(actionWith(actionData));
    },
    errorResponse => {
      if (!errorResponse.response) {
        return next(
          actionWith({
            type: 'SERVER_DOWN',
            error: true,
            status: 500,
            message: 'SERVER_DOWN',
          })
        );
      }

      if (errorResponse.response.status === 401 && !errorResponse.data.code) {
        return next(
          actionWith({
            type: 'AUTH_FAILURE',
            error: true,
            status: errorResponse.status,
            message: 'Your session has expired. Please login again.',
          })
        );
      }

      return next(
        actionWith({
          type: failureType,
          error: true,
          status: errorResponse.response.status,
          message: errorResponse.data.message,
        })
      );
    }
  );
};
