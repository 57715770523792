import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Avatar, Dropdown, Header } from 'hx-react-components';
import { ProductsDropdown, UserInfoDropdown } from '../../components';
import { selectFilteredUserProducts } from '../../selectors/';
import { withRouter } from 'react-router-dom';
import { getInitials } from '../../utils';
import { SSO_URLS } from '../../constants/config';
const logo = `${process.env.PUBLIC_URL}/logo-white.svg`;

const SSO_OVERVIEW_URL =
  process.env.REACT_APP_IS_LOCAL === 'true'
    ? SSO_URLS['localhost'].REACT_APP_ID_SERVER_BASE
    : SSO_URLS[process.env.REACT_APP_API_ENV].REACT_APP_ID_SERVER_BASE;

const HeaderContainer = ({ me, userProducts }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = me;
  return (
    <Header
      logo={logo}
      user={user}
      backgroundColor="dark"
      baseRoute={`${SSO_OVERVIEW_URL}/`}
      buttons={[<ProductsDropdown products={userProducts.data} />]}
      size="full"
      logoWidth="180px"
    >
      <Dropdown
        isOpen={isOpen}
        position={'left'}
        content={
          <UserInfoDropdown
            user={user}
            onCloseDropdown={() => setIsOpen(false)}
          />
        }
        onClickOutside={() => setIsOpen(false)}
      >
        <Avatar
          initials={getInitials(user)}
          src={user.avatar}
          size="medium"
          onAvatarClick={() => setIsOpen(!isOpen)}
          hoverEffect="standard"
        />
      </Dropdown>
    </Header>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  me: state.me,
  userProducts: selectFilteredUserProducts(state),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderContainer)
);
