import { Auth } from 'aws-amplify';

// User Sing In
const signIn = async (email, password) => {
  try {
    const sign_in = await Auth.signIn(email, password);
    return sign_in;
  } catch (e) {
    return e.message;
  }
};

// User Sign out globally
const signOut = async () => await Auth.signOut({ global: true });

// User Sign up
const signUp = async user => {
  try {
    await Auth.signUp(user);
    return true;
  } catch (e) {
    return e.message;
  }
};

/**
 * Get current authenticated user details
 */
const getCurrentUserInfo = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes;
  } catch (e) {
    return e;
  }
};

/**
 * Get current pool user
 */
const getCurrentUser = async () => {
  try {
    const user = await Auth.currentUserPoolUser();
    return user;
  } catch (e) {
    return e;
  }
};

/**
 * Get Current Session
 */
const getCurrentSession = async () => {
  try {
    const session = await Auth.currentSession();
    return session;
  } catch (e) {
    return e;
  }
};

/**
 * Get the access token from cognito
 */
const getAccessToken = res => {
  const { accessToken, signInUserSession } = res;

  if (!accessToken && !signInUserSession) {
    return null;
  }

  return accessToken
    ? accessToken.jwtToken
    : signInUserSession.accessToken.jwtToken;
};

/**
 * Get the id token from cognito
 */
const getIdToken = res => {
  const { idToken, signInUserSession } = res;

  if (!idToken && !signInUserSession) {
    return null;
  }

  return idToken ? idToken.jwtToken : signInUserSession.idToken.jwtToken;
};

const getRefreshToken = res => {
  const { refreshToken, signInUserSession } = res;

  if (!refreshToken && !signInUserSession) {
    return null;
  }

  return refreshToken
    ? refreshToken.token
    : signInUserSession.refreshToken.token;
};

/**
 * Get the user company id from decoded token
 */
const getCompanyId = decodedToken => {
  if (!decodedToken) {
    return null;
  }
  const { companyId } = decodedToken;
  return companyId;
};

/**
 * Get the user's scopes from decoded token, from string to array
 */
const getUserScopes = decodedToken => {
  const { scopes } = decodedToken;
  return scopes.split();
};

/**
 * Get the user's products from decoded token, from string to array
 */
const getUserProducts = decodedToken => {
  const { products } = decodedToken;
  return Object.keys(products);
};

const clearLocalSession = () => {
  Object.keys(localStorage).forEach(key => {
    if (key.includes('CognitoIdentityServiceProvider')) {
      localStorage.removeItem(key);
    }
  });
};

export {
  signIn,
  signOut,
  signUp,
  getCurrentUserInfo,
  getCurrentSession,
  getCurrentUser,
  getAccessToken,
  getIdToken,
  getCompanyId,
  getUserScopes,
  getUserProducts,
  clearLocalSession,
  getRefreshToken,
};
