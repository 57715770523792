import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  requestLogin,
  redirectWithTokens,
  resetAuth,
} from '../../actions/AuthActions';

import queryString from 'query-string';

import { Login } from '../../components';
import { getUserProducts } from '../../utils/CognitoHelperMethods';
import { storeTokens } from '../../utils/AuthHelperMethods';

class LoginContainer extends Component {
  getRedirectUrl() {
    const value = queryString.parse(this.props.location.search);
    const { redirectUrl } = value;
    return redirectUrl;
  }

  getAppID() {
    const value = queryString.parse(this.props.location.search);
    const { app_id } = value;
    return app_id;
  }

  hasAccesstoProduct = decodedToken => {
    const qs = queryString.parse(this.props.location.search);
    const { app_id } = qs;
    const productsAsArr = getUserProducts(decodedToken);

    if (productsAsArr.includes(app_id)) {
      return true;
    }

    return false;
  };

  componentDidUpdate() {
    const app_id = this.getAppID();
    const { auth, redirectWithTokens, history } = this.props;

    if (auth.isLogged) {
      if (auth.token) {
        storeTokens({
          refreshToken: auth.refreshToken,
          idToken: auth.token,
          accessToken: auth.accessToken,
        });
      }
      if (app_id) {
        const hasAccesstoProduct = this.hasAccesstoProduct(auth.decodedToken);
        if (auth.token && hasAccesstoProduct) {
          return redirectWithTokens(app_id);
        }

        return history.push('/');
      }

      history.push('/');
    }
  }

  componentWillUnmount() {
    this.props.resetAuth();
  }

  render() {
    const { requestLogin, auth } = this.props;
    return <Login requestLogin={requestLogin} auth={auth} />;
  }
}

const mapDispatchToProps = {
  requestLogin,
  redirectWithTokens,
  resetAuth,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginContainer)
);
