import React, { Component } from 'react';
import { getCurrentSession } from '../utils/CognitoHelperMethods';
import { isTokenExpired } from '../utils/AuthHelperMethods';

function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
}

function isDocumentVibile() {
  return document.visibilityState === 'visible';
}

function WithAuthCheckOnPageVisibility(WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      this.handlePageVisibityAuth();
    }
    handlePageVisibityAuth = () => {
      window.addEventListener(
        this.visibilityChange,
        this.onVisibilityChange,
        false
      );
    };
    visibilityChange = getBrowserVisibilityProp();

    onVisibilityChange = async () => {
      const { accessToken } = await getCurrentSession();
      const tokenExpired = accessToken && isTokenExpired(accessToken.jwtToken);
      if (tokenExpired && isDocumentVibile()) {
        console.log('tokenExpired', tokenExpired, 'TO DO');
      }
    };
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default WithAuthCheckOnPageVisibility;
