import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  ListGroup,
  FormItem,
  Loading,
} from 'hx-react-components';
import { Form } from '../';

const ChangeNamePopover = ({
  user,
  onPopoverClose,
  onChangeNameSubmit,
  isLoading,
}) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const saveEnabled = name.length > 0 && surname.length > 0;
  const handleOnSubmit = () => {
    onChangeNameSubmit(name, surname);
  };

  useEffect(() => {
    user.name && setName(user.name);
    user.surname && setSurname(user.surname);
  }, [user]);

  return (
    <Form onSubmit={handleOnSubmit} data-test-selector="change-fullname-form">
      <Box pX="medium" pT="medium" minHeight="168px">
        <Loading hideMode isLoading={isLoading}>
          <FormItem>
            <Input
              onChange={e => setName(e.target.value)}
              placeholder="Name"
              type="text"
              value={name}
            />
          </FormItem>

          <FormItem>
            <Input
              onChange={e => setSurname(e.target.value)}
              placeholder="Surname"
              type="text"
              value={surname}
            />
          </FormItem>
        </Loading>
      </Box>

      <footer style={{ borderTop: '1px solid #d8d7ea' }}>
        <Box pX="medium" pY="medium">
          <ListGroup
            direction="row"
            items={[
              <Button
                size="small"
                type="submit"
                disabled={!saveEnabled || isLoading}
              >
                Save
              </Button>,
              <Button
                size="small"
                variant={'outlined'}
                onClick={onPopoverClose}
              >
                Close
              </Button>,
            ]}
          />
        </Box>
      </footer>
    </Form>
  );
};

export default ChangeNamePopover;
