import styled, { css } from 'styled-components';

const getTransition = (collapsed, isChild, index = -1) => {
  if (collapsed) {
    if (!isChild) {
      return css`
        transition-timing-function: linear;
        transition-duration: 0.2s;
        transform: scale(0.9, 0.9) translate3d(0, 0, 0);
        background: #505050;
      `;
    }
    return css`
      transform: translate3d(0, -${60 * (index + 1)}px, 0);
      transition-duration: ${150 + index * 100}ms;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    `;
  }

  if (isChild) {
    return css`
      transform: translate3d(0, 0, 0);
    `;
  }
  return css`
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.4s;
    transition: transform ease-out 0.2s;
  `;
};
const StyledSupportButton = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 1000;
  cursor: pointer;
  background: ${props => props.theme.colors.primary};
  color: white;
  border-color: ${props => props.theme.colors.primary};
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 14px;
  box-shadow: 2px 2px 3px 0px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${props =>
      props.collapsed && !props.isChild
        ? '#424141'
        : props.theme.colors.primary_hover};
    border-color: ${props =>
      props.collapsed && !props.isChild
        ? '#424141'
        : props.theme.colors.primary_hover};
  }

  &:focus {
    outline: none;
  }
  transition: transform ease-out 0.2s;
  ${props => getTransition(props.collapsed, props.isChild, props.index)}
`;

export { StyledSupportButton };
