import React from 'react';
import { Text } from 'hx-react-components';

const ProductLicences = ({ licences }) => {
  return (
    <>
      <div>
        <Text tag="span" size="xbody" variant="dark_light">
          Licenses: {licences.total}
        </Text>
        {' - '}
        <Text tag="span" size="xbody" variant="dark_light">
          In use: {licences.total - licences.available}
        </Text>
      </div>
    </>
  );
};

export default ProductLicences;
