import React, { Component } from 'react';
import { TopBanner } from '../../components';

import {
  Button,
  Dropdown,
  Row,
  Col,
  Icon,
  Text,
  Box,
} from 'hx-react-components';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { showModal } from '../../actions/ModalActions';
import { requestUserProducts } from '../../actions/UserProductsActions';
import { LanguageConsumer } from '../../contexts';
import translations from '../../lang';
import { sendEvent } from '../../actions/EventsActions';

class AccountSettingsHeader extends Component {
  state = {
    licensesDropdownOpen: false,
  };

  componentDidMount() {
    this.props.requestUserProducts();
  }

  handleOpenModal = () => {
    sendEvent('Account Settings - Add new user');
    this.props.showModal(
      {
        isOpen: true,
        position: 'right',
      },
      'AddUser'
    );
  };

  showAddUserButton = (pathname, scopes) => {
    const hasUserScope = scopes && scopes.includes('users-admin');
    return (pathname === '/users' || pathname === '/account') && hasUserScope;
  };

  checkUserHasAvailbleLicences = userProducts => {
    if (!userProducts) {
      return false;
    }

    let count = 0;
    userProducts.forEach(product => {
      if (product) {
        count += product.licenses.available;
      }
    });

    return count > 0;
  };

  getDropdownWarningContent = () => {
    return (
      <LanguageConsumer>
        {lang => (
          <Box pY="large" pX="large" minWidth="300px">
            <Row valign="middle">
              <Col span="auto">
                <Icon icon="alert" variant="error" />
              </Col>
              <Col>
                <Text weight="semi-bold" size="body">
                  {
                    translations[lang].PAGE_ACCOUNT_SETTINGS[
                      'LICENSES_TERMINATED_TITLE'
                    ]
                  }
                </Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Box mT="medium">
                  <Text size="xbody">
                    {
                      translations[lang].PAGE_ACCOUNT_SETTINGS[
                        'LICENSES_TERMINATED_TEXT'
                      ]
                    }
                  </Text>
                </Box>
              </Col>
            </Row>
          </Box>
        )}
      </LanguageConsumer>
    );
  };

  toggleLicensesDropdown = show => {
    this.setState({
      licensesDropdownOpen: show,
    });
  };

  getAddUserButton = (
    showButton,
    userHasAvailableLicences,
    licensesDropdownOpen
  ) => {
    if (!showButton) {
      return null;
    }

    if (!userHasAvailableLicences) {
      const dropdownContent = this.getDropdownWarningContent();
      return (
        <LanguageConsumer>
          {lang => (
            <Dropdown
              position="left"
              content={dropdownContent}
              isOpen={licensesDropdownOpen}
            >
              <Button
                variant="primary"
                size="small"
                onMouseOver={() => this.toggleLicensesDropdown(true)}
                onMouseOut={() => this.toggleLicensesDropdown(false)}
              >
                {translations[lang].COMMON['ADD_USER']}
              </Button>
            </Dropdown>
          )}
        </LanguageConsumer>
      );
    }

    return (
      <LanguageConsumer>
        {lang => (
          <Button
            variant="primary"
            size="small"
            onClick={() => this.handleOpenModal()}
          >
            {translations[lang].COMMON['ADD_USER']}
          </Button>
        )}
      </LanguageConsumer>
    );
  };

  render() {
    const { auth, userProducts, isUserSectionDisabled } = this.props;
    const { scopes } = auth.decodedToken;
    const { licensesDropdownOpen } = this.state;

    const userHasAvailableLicences = this.checkUserHasAvailbleLicences(
      userProducts.data
    );

    const showButton =
      !isUserSectionDisabled &&
      this.showAddUserButton(this.props.location.pathname, scopes);

    const addUserButton = this.getAddUserButton(
      showButton,
      userHasAvailableLicences,
      licensesDropdownOpen
    );

    const isHomePage = this.props.history.location.pathname === '/';

    if (isHomePage) {
      return <></>;
    }
    return (
      <LanguageConsumer>
        {lang => (
          <TopBanner
            title={translations[lang].COMMON['ACCOUNT_AND_SETTINGS']}
            button={addUserButton}
          />
        )}
      </LanguageConsumer>
    );
  }
}

const mapDispatchToProps = {
  showModal,
  requestUserProducts,
};

const mapStateToProps = state => ({
  auth: state.auth,
  userProducts: state.userProducts,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountSettingsHeader)
);
