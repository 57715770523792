import { CALL_API } from '../middleware/api';

import {
  GET_USER_PRODUCTS_REQUEST,
  GET_USER_PRODUCTS_SUCCESS,
  GET_USER_PRODUCTS_FAILURE,
} from '../constants/action-types';

const fetchUserProducts = () => ({
  [CALL_API]: {
    types: [
      GET_USER_PRODUCTS_REQUEST,
      GET_USER_PRODUCTS_SUCCESS,
      GET_USER_PRODUCTS_FAILURE,
    ],
    endpoint: '/users/me/products?fusions=true',
    method: 'GET',
    mock: false,
  },
});

export const requestUserProducts = () => dispatch => {
  return dispatch(fetchUserProducts());
};
