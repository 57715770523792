import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NotLoggedLayout, AuthLayout } from '../layouts';

import { requestLogout } from '../actions/AuthActions';
import { Box, Loading } from 'hx-react-components';

class SignOut extends Component {
  componentDidMount() {
    this.props.requestLogout();
  }

  componentDidUpdate() {
    if (!this.props.auth.isLogged) {
      this.props.history.push({
        pathname: '/login',
      });
    }
  }

  render() {
    return (
      <NotLoggedLayout>
        <AuthLayout>
          <Box minHeight="200px">
            <Loading isLoading />
          </Box>
        </AuthLayout>
      </NotLoggedLayout>
    );
  }
}

const mapDispatchToProps = {
  requestLogout,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignOut)
);
