import React from 'react';
import { COLORS, ICONS } from '../../constants';
import styled from 'styled-components';
import theme from 'hx-theme';
import { Icon, InfoLabel } from 'hx-react-components';

const StyledProductIconImage = styled.div`
  position: absolute;
  inset: -1px;
`;
const StyledProductIcon = styled.div`
  border-color: white;
  height: 80px;
  width: 80px;
  transform: rotate(-10deg);
  border-style: solid;
  border-width: calc(${theme.spacing.xsmall} + 1px);
  border-radius: 30%;
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(25, 25, 39, 0.25);
  z-index: 1;
`;
const StyledProductVersion = styled.div`
  position: absolute;
  z-index: 1;
  left: -10px;
  top: 50px;
  transform: rotate(10deg);
`;

const ProductIcon = ({ product }) => {
  return (
    <StyledProductIcon>
      <StyledProductIconImage>
        <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '30%',
            background: COLORS.products[product.slug],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon
            variant="light"
            icon={ICONS.products[product.slug]}
            size="xlarge"
          />
        </div>
      </StyledProductIconImage>
      {product.slug === 'trends' && (
        <StyledProductVersion>
          <InfoLabel label="Beta" variant="pending" />
        </StyledProductVersion>
      )}
    </StyledProductIcon>
  );
};

export default ProductIcon;
