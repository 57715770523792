import { REMOVE_TOAST, ADD_TOAST } from '../constants/action-types';

import uniqid from 'uniqid';

export const addToast = toastProps => dispatch => {
  const id = uniqid();

  dispatch({
    type: ADD_TOAST,
    toastProps,
    id,
  });
};

export const removeToast = id => dispatch => {
  dispatch({
    type: REMOVE_TOAST,
    id,
  });
};
