import React from 'react';
import { Row, Col, Pagination } from 'hx-react-components';
import PropTypes from 'prop-types';

const PaginationBox = ({ pagination, onPageChange }) => {
  if (pagination.pageCount <= pagination.limit) {
    return false;
  }

  return (
    <Row halign="center">
      <Col span="auto">
        <Pagination
          totalPages={Math.ceil(pagination.pageCount / pagination.limit)}
          currentPage={pagination.page}
          onPageChangeRequest={onPageChange}
        />
      </Col>
    </Row>
  );
};

PaginationBox.propTypes = {
  pagination: PropTypes.object,
  onPageChange: PropTypes.func.isRequired,
};

export default PaginationBox;
