import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  TOGGLE_USER_PRODUCT_ASSIGNMENT,
  TOGGLE_USER_SCOPE_ASSIGNMENT,
  GET_NEW_USER_REQUEST,
  GET_NEW_USER_SUCCESS,
  GET_NEW_USER_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  REACTIVATE_DISABLE_USER_REQUEST,
  REACTIVATE_DISABLE_USER_SUCCESS,
  REACTIVATE_DISABLE_USER_FAILURE,
} from '../../constants/action-types';

import { isUndefined } from '../../utils';

const getUser = data => {
  return {
    ...data[1].response,
    products: getUserProductsWithAssignments(data),
    scopes: getScopesWithAssignments(data),
  };
};

const getScopesWithAssignments = data => {
  const userScopes = data[1].response.scopes;
  const allScopes = data[2].response;
  const meScopes = data[3];

  const filteredScopes = allScopes.filter(scope => {
    const exist = meScopes.filter(item => item === scope.slug).length > 0;
    return exist;
  });

  return filteredScopes.map(scope => {
    const userHasScope =
      userScopes.filter(userScope => userScope.id === scope.id).length > 0;

    return {
      ...scope,
      assigned: userHasScope,
    };
  });
};

const getUserProductsWithAssignments = data => {
  const availableProducts = data[0].response;
  const userProducts = data[1].response.products;
  return availableProducts.map(product => {
    const assignedProduct = userProducts.filter(
      userProduct => userProduct.id === product.id
    )[0];
    const fusions = (product.fusions || [])
      .filter(f => f.product_id === product.id)
      .map(f => ({
        ...f,
        assigned: userHasItem(data[1].response.fusions, f.id),
      }));
    return {
      ...product,
      assigned: userHasItem(userProducts, product.id),
      addons: getAddonsWithAssignments(product.addons, assignedProduct),
      fusions,
    };
  });
};

const getAddonsWithAssignments = (availableAddons, assignedProduct) => {
  return availableAddons
    ? availableAddons.map(addon => {
        return {
          ...addon,
          assigned:
            assignedProduct && userHasItem(assignedProduct.addons, addon.id),
        };
      })
    : [];
};

const userHasItem = (items, itemId) => {
  if (!items) {
    return false;
  }
  return items.filter(item => item.id === itemId).length === 1;
};

const setLicenses = (assigned, available) => {
  if (!assigned) {
    return available + 1;
  }
  return available - 1;
};

const toggleUserProductAssignment = (
  data,
  { assigned, productId, addonId, fusionId }
) => {
  const newProds = [...data];

  const commonProductChanges = (product, assigned) => {
    return {
      ...product,
      licenses: {
        ...product.licenses,
        available: setLicenses(assigned, product.licenses.available),
      },
      assigned,
    };
  };

  return newProds.map(product => {
    if (product.id !== productId) {
      return product;
    }

    if (isUndefined(fusionId) && isUndefined(addonId)) {
      if (assigned) {
        /** productd assigned */
        return {
          ...commonProductChanges(product, assigned),
        };
      } else {
        /** productd not assigned */
        const uncheckAddons = product.addons
          ? product.addons.map(item => ({
              ...item,
              assigned: false,
            }))
          : undefined;

        const uncheckFusions = product.fusions
          ? product.fusions.map(item => ({
              ...item,
              assigned: false,
            }))
          : undefined;

        return {
          ...commonProductChanges(product, assigned),
          addons: uncheckAddons,
          fusions: uncheckFusions,
        };
      }
    }

    /** addon toogled */
    if (!isUndefined(addonId)) {
      return {
        ...product,
        addons: product.addons.map(addon => {
          if (addon.id !== addonId) {
            return addon;
          }

          return {
            ...addon,
            assigned,
          };
        }),
      };
    }

    /** fusion toogled */
    if (!isUndefined(fusionId)) {
      return {
        ...product,
        fusions: product.fusions.map(fusion => {
          if (fusion.id !== fusionId) {
            return fusion;
          }

          return {
            ...fusion,
            assigned,
          };
        }),
      };
    }

    return newProds;
  });
};

const toggleUserScopeAssignment = (data, { assigned, scopeId }) => {
  const newScopes = [...data];

  return newScopes.map(scope => {
    if (scope.id !== scopeId) {
      return scope;
    }

    return {
      ...scope,
      assigned,
    };
  });
};

/** New user Data */
const getProducts = data => {
  const meProducts = [...data[0].data];
  return meProducts.map(product => ({
    ...product,
    assigned: false,
  }));
};

const getScopes = data => {
  const allScopes = [...data[1].response];
  const meScopes = [...data[2]];
  return allScopes.filter(scope => {
    return meScopes.filter(meScope => meScope === scope.slug).length > 0;
  });
};

const getNewUserData = (state, data) => {
  return {
    ...state,
    products: getProducts(data),
    scopes: getScopes(data),
  };
};

/** End New user Data */

const getInitialState = () => {
  return {
    isLoading: true,
    data: {},
    error: false,
  };
};

const initialState = getInitialState();

const user = (state = initialState, action) => {
  const types = {
    [ADD_USER_REQUEST]: () => ({
      ...state,
      error: false,
      isLoading: true,
    }),
    [ADD_USER_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      error: false,
      message: action.message,
    }),
    [ADD_USER_FAILURE]: () => ({
      ...state,
      isLoading: false,
      error: true,
      message: action.message,
    }),
    [GET_USER_REQUEST]: () => ({
      ...state,
      isLoading: true,
    }),
    [GET_USER_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      error: false,
      message: action.message,
      data: getUser(action.response),
    }),
    [GET_USER_FAILURE]: () => ({
      ...state,
      isLoading: false,
      error: true,
      message: action.message,
    }),
    [GET_NEW_USER_REQUEST]: () => ({
      ...state,
      error: false,
      isLoading: true,
    }),
    [GET_NEW_USER_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      data: getNewUserData(state.newUser, action.response),
    }),
    [GET_NEW_USER_FAILURE]: () => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [EDIT_USER_REQUEST]: () => ({
      ...state,
      isLoading: true,
    }),
    [EDIT_USER_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      error: false,
      message: action.message,
    }),
    [EDIT_USER_FAILURE]: () => ({
      ...state,
      isLoading: false,
      error: true,
      message: action.message,
    }),
    [REACTIVATE_DISABLE_USER_REQUEST]: () => ({
      ...state,
      modalLoading: true,
    }),
    [REACTIVATE_DISABLE_USER_SUCCESS]: () => ({
      ...state,
      modalLoading: false,
      data: {
        ...state.data,
        status: 'pending',
      },
    }),
    [REACTIVATE_DISABLE_USER_FAILURE]: () => ({
      ...state,
      modalLoading: false,
    }),
    [TOGGLE_USER_PRODUCT_ASSIGNMENT]: () => ({
      ...state,
      data: {
        ...state.data,
        products: toggleUserProductAssignment(state.data.products, action.data),
      },
    }),
    [TOGGLE_USER_SCOPE_ASSIGNMENT]: () => ({
      ...state,
      data: {
        ...state.data,
        scopes: toggleUserScopeAssignment(state.data.scopes, action.data),
      },
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default user;
