import React, { useCallback, useMemo } from 'react';
import {
  Anchor,
  ListGroup,
  ListItem,
  Text,
  UserInfo,
} from 'hx-react-components';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { SSO_URLS } from '../../constants/config';
import { sendEvent } from '../../actions/EventsActions';

const StyledUserInfoWrapper = styled.div`
  background-color: rgb(241, 240, 255);
  padding: 20px;
`;

const StyledLink = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;

  &:hover {
    background-color: rgb(241, 240, 255);
  }
`;

const UserInfoDropdown = ({ user, onCloseDropdown, history }) => {
  const { name, surname, avatar } = user;

  if (!name || !surname) {
    return <div />;
  }

  const SSO_OVERVIEW_URL =
    process.env.REACT_APP_IS_LOCAL === 'true'
      ? SSO_URLS['localhost'].REACT_APP_ID_SERVER_BASE
      : SSO_URLS[process.env.REACT_APP_API_ENV].REACT_APP_ID_SERVER_BASE;

  const isHomePage = useMemo(() => {
    return history.location.pathname === '/';
  }, [history]);

  const goToAccountSettings = useCallback(() => {
    sendEvent('Go to Account Settings');
    window.location.href = `${SSO_OVERVIEW_URL}/account-settings/overview`;
  }, []);

  const userDropdownLinks = useMemo(() => {
    const links = [];

    if (isHomePage) {
      links.push(
        <Anchor
          target="_blank"
          style={{ textDecoration: 'none' }}
          onClick={() => goToAccountSettings()}
          rel="noopener noreferrer"
        >
          <StyledLink>
            <Text tag="span" size="xbody">
              Account & Settings
            </Text>
          </StyledLink>
        </Anchor>
      );
    }
    links.push(
      <ListItem
        text={'Logout'}
        onClick={() => {
          sendEvent('Logout');
          onCloseDropdown();
          history.push('/logout');
        }}
      />
    );

    return links;
  }, [isHomePage]);

  return (
    <div>
      <StyledUserInfoWrapper>
        <UserInfo
          userName={`${name} ${surname}`}
          initials={`${name.charAt(0)}${surname.charAt(0)}`}
          image={avatar}
          email={user.email}
        />
      </StyledUserInfoWrapper>
      <div style={{ padding: '10px' }}>
        <ListGroup size="xxsmall" items={userDropdownLinks} />
      </div>
    </div>
  );
};

export default withRouter(UserInfoDropdown);
