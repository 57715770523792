import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
} from '../../constants/action-types';

const getInitialState = () => {
  return {
    isLoading: true,
    data: [],
    error: false,
    params: {
      q: '',
      pagination: {
        page: 1,
        limit: 8,
      },
      sort: {},
      filters: [],
    },
  };
};

const initialState = getInitialState();

const products = (state = initialState, action) => {
  const types = {
    [GET_PRODUCTS_REQUEST]: () => ({
      ...state,
      params: {
        ...state.params,
        ...action.params,
      },
      error: false,
    }),
    [GET_PRODUCTS_SUCCESS]: () => ({
      ...state,
      isLoading: false,
      data: action.response,
      pageCount: action.pageCount,
      error: false,
    }),
    [GET_PRODUCTS_FAILURE]: () => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  };

  if (!types[action.type]) {
    return state;
  }

  return types[action.type]();
};

export default products;
