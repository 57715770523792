import React from 'react';
import { NotLoggedLayout, AuthLayout } from '../layouts';

import { Text, Row, Col, Box, Anchor } from 'hx-react-components';

const InvitationLinkExpired = () => (
  <NotLoggedLayout>
    <AuthLayout>
      <Box mB="large" mT="xlarge">
        <Row halign="center">
          <Col span={'auto'}>
            <Text
              align="center"
              tag="h1"
              variant="dark_lighter"
              size="headline"
            >
              Ooops!
            </Text>
            <Box mT="large">
              <Text align="center" tag="h2" variant="light" size="body">
                The invitation link is no longer valid.
                <br />
                Please contact your company administrator
                <br />
                for Helixa or contact{' '}
                <Anchor
                  target="_blank"
                  href={'http://support.helixa.ai/support/tickets/new'}
                >
                  Helixa Support
                </Anchor>
              </Text>
            </Box>
          </Col>
        </Row>
      </Box>
    </AuthLayout>
  </NotLoggedLayout>
);

export default InvitationLinkExpired;
