import React from 'react';
import { NotLoggedLayout, AuthLayout } from '../../layouts';
import { RegistrationContainer } from '../../containers';

const Registration = props => (
  <NotLoggedLayout>
    <AuthLayout>
      <RegistrationContainer {...props} />
    </AuthLayout>
  </NotLoggedLayout>
);

export default Registration;
