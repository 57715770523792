import React from 'react';
import { Container, Row, Col } from 'hx-react-components';

const AccountLayout = ({ left, top, children }) => (
  <Container>
    {top}
    <Row gutter="medium">
      {left && <Col span={2}>{left}</Col>}
      <Col span={left ? 10 : 12}>{children}</Col>
    </Row>
  </Container>
);

export default AccountLayout;
