import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loading, Box } from 'hx-react-components';
import { initialAuth } from '../../actions/AuthActions';
import { requestMe } from '../../actions/UsersActions';
import { NotLoggedLayout, AuthLayout } from '../../layouts';

class InitContainer extends Component {
  publicRoutes = [
    '/login',
    '/registration',
    '/reset-password',
    '/logout',
    '/forgot-password',
    '/error',
    '/invitation-expired',
  ];

  componentDidMount() {
    const isRequestingSingleSingOff = this.isRequestingSingleSingOff();
    !isRequestingSingleSingOff && this.props.initialAuth();
  }

  componentDidUpdate() {
    const shouldCallMeData = this.shouldCallMeData();
    if (shouldCallMeData) {
      this.props.requestMe();
    }
  }

  isRequestingSingleSingOff() {
    return this.props.location.pathname === '/logout';
  }

  isRequestingSingleSingOn() {
    const { search } = this.props.location;
    const hasRedirectUrl = search.includes('redirectUrl');
    return this.props.location.pathname === '/login' && hasRedirectUrl;
  }

  shouldCallMeData = () => {
    const isPublicPath = this.isPublicPath();
    return (
      !isPublicPath &&
      this.props.auth.isLogged &&
      !this.props.me.user.email &&
      !this.props.me.isLoading
    );
  };

  canShowChildren = () => {
    const isRequestingSingleSingOn = this.isRequestingSingleSingOn();
    const isRequestingSingleSingOff = this.isRequestingSingleSingOff();
    const isPublicPath = this.isPublicPath();
    const authCheckDone = this.props.auth.init;

    if (isRequestingSingleSingOff) {
      return true;
    }

    if (
      isRequestingSingleSingOn &&
      !authCheckDone &&
      !this.props.auth.hasLoggedOut
    ) {
      return false;
    }

    if (isPublicPath) {
      return true;
    }

    return authCheckDone && !this.props.me.isLoading;
  };

  isPublicPath = () => {
    return this.publicRoutes.includes(this.props.location.pathname);
  };

  render() {
    const canShowChildren = this.canShowChildren();
    if (!canShowChildren) {
      return (
        <NotLoggedLayout>
          <AuthLayout>
            <Box minHeight="200px">
              <Loading isLoading={true} />
            </Box>
          </AuthLayout>
        </NotLoggedLayout>
      );
    }
    return this.props.children;
  }
}

const mapDispatchToProps = {
  initialAuth,
  requestMe,
};

const mapStateToProps = state => ({
  auth: state.auth,
  me: state.me,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InitContainer)
);
