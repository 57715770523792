const AUTH_REQUEST = 'AUTH_REQUEST';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAILURE = 'AUTH_FAILURE';
const AUTH_CHECK_REQUEST = 'AUTH_CHECK_REQUEST';
const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS';
const AUTH_CHECK_FAILURE = 'AUTH_CHECK_FAILURE';

const RESET_PASSWORDS_REQUEST = 'RESET_PASSWORDS_REQUEST';
const RESET_PASSWORDS_SUCCESS = 'RESET_PASSWORDS_SUCCESS';
const RESET_PASSWORDS_FAILURE = 'RESET_PASSWORDS_FAILURE';

const GET_ME_REQUEST = 'GET_ME_REQUEST';
const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
const GET_ME_FAILURE = 'GET_ME_FAILURE';

const AUTH_APP_START = 'AUTH_APP_START';

const CHECK_VALIDATION_TOKEN_REQUEST = 'CHECK_VALIDATION_TOKEN_REQUEST';
const CHECK_VALIDATION_TOKEN_SUCCESS = 'CHECK_VALIDATION_TOKEN_SUCCESS';
const CHECK_VALIDATION_TOKEN_FAILURE = 'CHECK_VALIDATION_TOKEN_FAILURE';
const CHECK_VALIDATION_TOKEN_VALID = 'CHECK_VALIDATION_TOKEN_VALID';

const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
const RESET_CHANGE_PASSWORD = 'RESET_CHANGE_PASSWORD';
const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

const GET_USER_REQUEST = 'GET_USER_REQUEST';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_FAILURE = 'GET_USER_FAILURE';

const RESET_USERS_PARAMS = 'RESET_USERS_PARAMS';
const RESET_USERS_FILTERS = 'RESET_USERS_FILTERS';

const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

const GET_USER_PRODUCTS_REQUEST = 'GET_USER_PRODUCTS_REQUEST';
const GET_USER_PRODUCTS_SUCCESS = 'GET_USER_PRODUCTS_SUCCESS';
const GET_USER_PRODUCTS_FAILURE = 'GET_USER_PRODUCTS_FAILURE';

const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

const GET_SCOPES_REQUEST = 'GET_SCOPES_REQUEST';
const GET_SCOPES_SUCCESS = 'GET_SCOPES_SUCCESS';
const GET_SCOPES_FAILURE = 'GET_SCOPES_FAILURE';

const GET_ADDONS_REQUEST = 'GET_ADDONS_REQUEST';
const GET_ADDONS_SUCCESS = 'GET_ADDONS_SUCCESS';
const GET_ADDONS_FAILURE = 'GET_ADDONS_FAILURE';

const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';

const ADD_TOAST = 'ADD_TOAST';
const REMOVE_TOAST = 'REMOVE_TOAST';

const GET_BILLINGS_REQUEST = 'GET_BILLINGS_REQUEST';
const GET_BILLINGS_SUCCESS = 'GET_BILLINGS_SUCCESS';
const GET_BILLINGS_FAILURE = 'GET_BILLINGS_FAILURE';

const DISABLE_USERS_REQUEST = 'DISABLE_USER_REQUEST';
const DISABLE_USERS_SUCCESS = 'DISABLE_USER_SUCCESS';
const DISABLE_USERS_FAILURE = 'DISABLE_USER_FAILURE';

const REACTIVATE_DISABLE_USER_REQUEST = 'REACTIVATE_DISABLE_USER_REQUEST';
const REACTIVATE_DISABLE_USER_SUCCESS = 'REACTIVATE_DISABLE_USER_SUCCESS';
const REACTIVATE_DISABLE_USER_FAILURE = 'REACTIVATE_DISABLE_USER_FAILURE';

const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

const RESET_AUTH = 'RESET_AUTH';

const GET_COMPANY_DETAIL_REQUEST = 'GET_COMPANY_DETAIL_REQUEST';
const GET_COMPANY_DETAIL_SUCCESS = 'GET_COMPANY_DETAIL_SUCCESS';
const GET_COMPANY_DETAIL_FAILURE = 'GET_COMPANY_DETAIL_FAILURE';

const RESEND_USER_INVITE_REQUEST = 'RESEND_USER_INVITE_REQUEST';
const RESEND_USER_INVITE_SUCCESS = 'RESEND_USER_INVITE_SUCCESS';
const RESEND_USER_INVITE_FAILURE = 'RESEND_USER_INVITE_FAILURE';

const TOGGLE_USER_PRODUCT_ASSIGNMENT = 'TOGGLE_USER_PRODUCT_ASSIGNMENT';
const TOGGLE_USER_SCOPE_ASSIGNMENT = 'TOGGLE_USER_SCOPE_ASSIGNMENT';

const GET_NEW_USER_REQUEST = 'GET_NEW_USER_REQUEST';
const GET_NEW_USER_SUCCESS = 'GET_NEW_USER_SUCCESS';
const GET_NEW_USER_FAILURE = 'GET_NEW_USER_FAILURE';

const TOGGLE_SELECT_USER = 'TOGGLE_SELECT_USER';
const TOGGLE_SELECT_ALL_USERS = 'TOGGLE_SELECT_ALL_USERS';

const UPDTAE_AVATAR_REQUEST = 'UPDTAE_AVATAR_REQUEST';
const UPDTAE_AVATAR_SUCCESS = 'UPDTAE_AVATAR_SUCCESS';
const UPDTAE_AVATAR_FAILURE = 'UPDTAE_AVATAR_FAILURE';

const UPDTAE_USER_FULLNAME_REQUEST = 'UPDTAE_USER_FULLNAME_REQUEST';
const UPDTAE_USER_FULLNAME_SUCCESS = 'UPDTAE_USER_FULLNAME_SUCCESS';
const UPDTAE_USER_FULLNAME_FAILURE = 'UPDTAE_USER_FULLNAME_FAILURE';

const RESET_PWD_REACTIVATE_USER_REQUEST = 'RESET_PWD_REACTIVATE_USER_REQUEST';
const RESET_PWD_REACTIVATE_USER_SUCCESS = 'RESET_PWD_REACTIVATE_USER_SUCCESS';
const RESET_PWD_REACTIVATE_USER_FAIULURE = 'RESET_PWD_REACTIVATE_USER_FAIULURE';

const RESTORE_PROUCTS_LICENSES = 'RESTORE_PROUCTS_LICENSES';

const SERVER_DOWN = 'SERVER_DOWN';
const RESET_GENERIC_ERROR = 'RESET_GENERIC_ERROR';

const TOGGLE_CHANGE_ME_FULLNAME_POPOVER = 'TOGGLE_CHANGE_ME_FULLNAME_POPOVER';

const CREATE_SUPPORT_TICKET_REQUEST = 'CREATE_SUPPORT_TICKET_REQUEST';
const CREATE_SUPPORT_TICKET_SUCCESS = 'CREATE_SUPPORT_TICKET_SUCCESS';
const CREATE_SUPPORT_TICKET_FAILURE = 'CREATE_SUPPORT_TICKET_FAILURE';

export {
  AUTH_APP_START,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_CHECK_REQUEST,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_FAILURE,
  RESET_PASSWORDS_REQUEST,
  RESET_PASSWORDS_SUCCESS,
  RESET_PASSWORDS_FAILURE,
  GET_ME_REQUEST,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
  CHECK_VALIDATION_TOKEN_REQUEST,
  CHECK_VALIDATION_TOKEN_SUCCESS,
  CHECK_VALIDATION_TOKEN_FAILURE,
  CHECK_VALIDATION_TOKEN_VALID,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESET_CHANGE_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  RESET_USERS_PARAMS,
  RESET_USERS_FILTERS,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  GET_USER_PRODUCTS_REQUEST,
  GET_USER_PRODUCTS_SUCCESS,
  GET_USER_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_SCOPES_REQUEST,
  GET_SCOPES_SUCCESS,
  GET_SCOPES_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_ADDONS_REQUEST,
  GET_ADDONS_SUCCESS,
  GET_ADDONS_FAILURE,
  SHOW_MODAL,
  HIDE_MODAL,
  REMOVE_TOAST,
  ADD_TOAST,
  GET_BILLINGS_REQUEST,
  GET_BILLINGS_SUCCESS,
  GET_BILLINGS_FAILURE,
  DISABLE_USERS_REQUEST,
  DISABLE_USERS_SUCCESS,
  DISABLE_USERS_FAILURE,
  REACTIVATE_DISABLE_USER_REQUEST,
  REACTIVATE_DISABLE_USER_SUCCESS,
  REACTIVATE_DISABLE_USER_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  RESET_AUTH,
  GET_COMPANY_DETAIL_REQUEST,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAILURE,
  RESEND_USER_INVITE_REQUEST,
  RESEND_USER_INVITE_SUCCESS,
  RESEND_USER_INVITE_FAILURE,
  TOGGLE_USER_PRODUCT_ASSIGNMENT,
  TOGGLE_USER_SCOPE_ASSIGNMENT,
  GET_NEW_USER_REQUEST,
  GET_NEW_USER_SUCCESS,
  GET_NEW_USER_FAILURE,
  TOGGLE_SELECT_USER,
  TOGGLE_SELECT_ALL_USERS,
  UPDTAE_AVATAR_REQUEST,
  UPDTAE_AVATAR_SUCCESS,
  UPDTAE_AVATAR_FAILURE,
  RESET_PWD_REACTIVATE_USER_REQUEST,
  RESET_PWD_REACTIVATE_USER_SUCCESS,
  RESET_PWD_REACTIVATE_USER_FAIULURE,
  RESTORE_PROUCTS_LICENSES,
  SERVER_DOWN,
  RESET_GENERIC_ERROR,
  UPDTAE_USER_FULLNAME_REQUEST,
  UPDTAE_USER_FULLNAME_SUCCESS,
  UPDTAE_USER_FULLNAME_FAILURE,
  TOGGLE_CHANGE_ME_FULLNAME_POPOVER,
  CREATE_SUPPORT_TICKET_REQUEST,
  CREATE_SUPPORT_TICKET_SUCCESS,
  CREATE_SUPPORT_TICKET_FAILURE,
};
