import styled from 'styled-components';

const MinResolutionContainer = styled.div`
  min-height: 100vh;
  width:100%;
  height: 100%;
  background-image: url('${process.env.PUBLIC_URL}/background.png');
  background-size:cover;
  background-color: #1A1A1F;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => props.theme.zindex.xlarge};
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const LogoContainer = styled.div`
  padding: 50px 0 25px;
  overflow: hidden;
`;

const VerticalCentered = styled.div`
  min-height: calc(100vh - 164px);
  display: flex;
  align-items: center;
  margin-top: -72px;
`;

export { MinResolutionContainer, LogoContainer, VerticalCentered };
