import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SupportButton } from '../../components';

import { showModal } from '../../actions/ModalActions';

class SupportModalOpener extends Component {
  openSupportModal = () => {
    this.props.showModal(
      {
        isOpen: true,
        hasScrim: false,
        position: 'right',
      },
      'Support'
    );
  };

  render() {
    return <SupportButton onSupportButtonClick={this.openSupportModal} />;
  }
}

const mapDispatchToProps = {
  showModal,
};

const mapStateToProps = state => ({
  modals: state.modals,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportModalOpener);
