import { applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import api from '../middleware/api';
import * as rootReducer from '../reducers';

import {
  auth,
  me,
  toasts,
  modals,
  genericError,
  products,
  scopes,
  users,
  resetPasswords,
  changePassword,
  userProducts,
  company,
  user,
} from '../reducers/';

import { storeManager } from './ReducerManager';

const staticReducers = {
  auth,
  me,
  toasts,
  modals,
  genericError,
  products,
  scopes,
  users,
  resetPasswords,
  changePassword,
  userProducts,
  company,
  user,
};

const configureStore = preloadedState => {
  storeManager.registerReducers(staticReducers);
  const store = storeManager.createStore(
    preloadedState,
    compose(applyMiddleware(thunk, api))
  );

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default configureStore;
