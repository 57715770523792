import React from 'react';
import styled from 'styled-components';
import { Text, Row, Col, Box } from 'hx-react-components';

const StyledBanner = styled.div`
  min-height: 34px;
`;

const TopBanner = ({ title, button }) => (
  <Box pY="xlarge">
    <StyledBanner>
      <Row halign="between">
        <Col span="auto">
          <Text lettercase="uppercase" size="xheadline">
            {title}
          </Text>
        </Col>
        {button && <Col span="auto">{button}</Col>}
      </Row>
    </StyledBanner>
  </Box>
);

export default TopBanner;
