import React from 'react';

import { Text, Row, Col, Toggle, Icon, Box } from 'hx-react-components';

import { iconsMap } from '../../mappers';

const ScopesToggleList = ({ scopes, onScopeToggle }) => {
  return scopes.map((scope, index) => (
    <Box key={index} mB="medium">
      <Row valign="middle">
        <Col span="auto">
          <Toggle
            checked={scope.assigned}
            onChange={e => onScopeToggle(!scope.assigned, scope.id)}
          />
        </Col>
        <Col span="auto">
          <Row valign="middle" gutter="small">
            <Col span="auto">
              <Icon icon={iconsMap[scope.slug]} />
            </Col>
            <Col span="auto">
              <Text lettercase="capitalize">{scope.name}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Box>
  ));
};

export default ScopesToggleList;
